export const INPUT_CONTAINER = "";

export const TEXT_AREA_CUSTOM = " textArea h-20 pt-3";

export const LABEL =
  "font-medium text-[13px] md:text-base text-LIGHT_BLACK pb-1";
export const UNDER_THE_FIELD =
  "flex flex-row justify-between items-center pt-1";
export const CHARACTER_COUNTER =
  "font-Montserrat font-medium text-Outlines text-[13px]";
