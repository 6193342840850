import PropTypes from "prop-types";
import RichTextComponent from "../RichTextComponent";
import {
  fontSize,
  fontSizeLg,
  fontSizeSm,
  fontType,
  textAlign,
  textColors,
  textDecoration,
} from "./fontConversion";

const TypographyPlayfair = ({
  text,
  children,
  fontWeight,
  size,
  color,
  align,
  decoration,
}) => {
  const className = `font-Playfair ${color ? textColors[color] : "text-black"
    } ${fontType[fontWeight]} ${fontSizeLg[size]}  ${fontSize[size]} ${fontSizeSm[size]
    } ${textAlign[align]} ${textDecoration[decoration]} `;

  const typography = children ? (
    <RichTextComponent text={children} className={className} />
  ) : (
    <p className={className}>{text}</p>
  );

  return typography;
};
TypographyPlayfair.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.array,
  fontWeight: PropTypes.string,
};

export default TypographyPlayfair;
