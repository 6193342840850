import React from "react";
import SerenaProductFlatlays from "../../components/Molecules/SerenaProductFlatlays/SerenaProductFlatlays";

/**
 * @typedef {import("@prismicio/client").Content.ProductFlatlaysSlice} ProductFlatlaysSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ProductFlatlaysSlice>} ProductFlatlaysProps
 * @param { ProductFlatlaysProps }
 */
const ProductFlatlays = ({ slice }) => (
  <section>
    <SerenaProductFlatlays data={slice?.primary} items={slice?.items} />
  </section>
);

export default ProductFlatlays;
