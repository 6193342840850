import React from "react";
import { useRouter } from "next/navigation";
import Slider from "react-slick";
import dynamic from "next/dynamic";

import {
  settings,
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./constants";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const TestimonialCard = dynamic(() =>
  import("../../Molecules/TestimonialCard")
);
const StylingSessionDescription = dynamic(() =>
  import("../../Atoms/Typography/StylingSessionDescription")
);

const TestimonialsSection = ({ data }) => {
  const router = useRouter();
  return (
    <div>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        <StylingSessionDescription text={data?.primary?.description} />
      </div>

      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <Slider {...settings}>
          {data?.items?.map((item, index) => {
            return (
              <TestimonialCard
                key={index}
                image={item?.clients_image}
                description={item?.review?.[0]?.text}
                instagram={item?.instagram_handle?.[0]?.text}
                language={router?.locale}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialsSection;
