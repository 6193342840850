export const THIS_OR_THAT_INNER_CONTAINER_ONE =
  "max-w-full md:max-w-[80%]  mx-2 md:mx-auto  ";
export const THIS_OR_THAT_INNER_CONTAINER_TWO =
  "max-w-full lg:max-w-[80%] mx-auto ";
export const CARDS_CONTAINER =
  " max-w-screen-lg  mx-auto grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-x-2 md:gap-x-12 gap-y-4 md:gap-y-14 my-12 ";
export const THIS = "This";
export const THAT = "That";
export const START_STYLE_QUIZ = "Start the Style Quiz";
export const NEXT_STEP_TEXT =
  "text-base md:text-2xl text-Text_Black py-12 font-normal leading-4";
export const NEXT_STEP_CONTAINER =
  "flex flex-col justify-around items-center max-w-full lg:max-w-[60%]  mx-2 lg:mx-auto ";
export const THIS_OR_THAT_SECTION_BUTTON_ID_TEXT =
  "this-or-that-section-button";

export const THIS_BUTTON_ID_TEXT = "this_button_id";

export const THAT_BUTTON_ID_TEXT = "that_button_id";
