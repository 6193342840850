import React from "react";
import SectionHeading from "../../Atoms/Typography/SectionHeading";
import SessionDescription from "../../Atoms/Typography/SessionDescription";
import JournalCard from "../JournalCard";
import NewPrimaryButton from "../../Atoms/Buttons/NewPrimaryButton";
import {
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import {
  BUTTON_CLASS,
  BUTTON_CONTAINER,
  HEADING_CONATINER,
  ITEM_CONTAINER,
  WRAPPER_CONTAINER,
} from "./style";
import { JOURNAL } from "./constant.js";

const JournalSection = ({ data, items }) => {
  return (
    <div className={WRAPPER_CONTAINER}>
      <div className={HEADING_CONATINER}>
        <SectionHeading text={data?.title} />
        <SessionDescription text={data?.description} />
      </div>
      <div className={ITEM_CONTAINER}>
        {items?.map((item, index) => {
          return <JournalCard cardData={item} key={index} />;
        })}
      </div>
      <div className={BUTTON_CONTAINER}>
        <NewPrimaryButton
          text={data?.button_text}
          href={data?.button_link}
          section={SURVEY_START_SECTIONS?.Journal}
          buttonId={JOURNAL}
          className={BUTTON_CLASS}
        />
      </div>
    </div>
  );
};

export default JournalSection;
