import React from "react";
import dynamic from "next/dynamic";

import {
  BANNER_BUTTON_ID_TEXT,
  BREAK_POINT_WIDTH,
  HERO_CONTENT_CONTAINER,
  HERO_CONTENT_INNER_CONTAINER,
  HERO_MOBILE_VIDEO_CLASS,
  HERO_VIDEO_CONTAINER,
  POSITION_RELATIVE,
  TAG_LINE_CLASS,
  BUTTON_CLASS,
} from "./constants";
import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

const NewHeroText = dynamic(() => import("../../Atoms/Typography/NewHeroText"));
const NewHeroDescription = dynamic(() =>
  import("../../Atoms/Typography/NewHeroDescription")
);
const NewPrimaryButtonWrapper = dynamic(() =>
  import("../../Atoms/Buttons/NewPrimaryButtonWrapper")
);
const BannerSection = ({ data }) => {
  const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);
  const videoUrl = isBreakpoint
    ? data?.mobile_banner_video?.url
    : data?.banner_video?.url;
  const posterUrl = isBreakpoint
    ? data?.mobile_banner_image?.url
    : data?.banner_image?.url;

  return (
    <div className={POSITION_RELATIVE}>
      <div className={HERO_VIDEO_CONTAINER}>
        <video
          poster={posterUrl}
          playsInline={true}
          webkit-playsinline={"true"}
          disablePictureInPicture={true}
          autoPlay={true}
          muted={true}
          preload="none"
          loop={true}
          className={HERO_MOBILE_VIDEO_CLASS}
          src={videoUrl}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
      </div>
      <div className={HERO_CONTENT_CONTAINER}>
        <div className={HERO_CONTENT_INNER_CONTAINER}>
          <NewHeroDescription text={data?.description} />
          <NewHeroText text={data?.title} />
          <NewHeroDescription
            text={data?.tag_line}
            customStyles={TAG_LINE_CLASS}
          />
          <div className={BUTTON_CLASS}>
            <NewPrimaryButtonWrapper
              text={data?.button_text}
              href={SIGN_UP_ENDPOINT}
              section={SURVEY_START_SECTIONS?.HomeBanner}
              buttonId={BANNER_BUTTON_ID_TEXT}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerSection;
