export const MODAL_CONTAINER =
  "flex justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none";
export const MODAL_SUB_CONTAINER =
  "w-[343px] md:w-[520px] h-auto  relative my-6 mx-auto max-w-3xl";
export const MODAL_CONTENT_CONTAINER =
  "border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none";
export const MODAL_HEADER = "flex justify-center mt-[20px] rounded-t";
export const MODAL_BODY =
  "relative mt-[25px] mb-[3px] px-[20px] flex-auto text-center text-sm md:text-lg";
export const MODAL_BODY_TEXT_CLASS = "text-Montserrat  text-lg text-center";
export const MODAL_FOOTER =
  "flex flex-col  mt-[20px] md:mt-[30px] mb-[20px] rounded-b";
export const BACKGROUND_SHADOW =
  "opacity-75 fixed inset-0 z-40 bg-WHITE_EIGHTY_PERCENT";
export const PRIMARY_BUTTON_CONTAINER = "mb-[12px] self-center";
export const TERTIARY_BUTTON_CLASS =
  "md:text-base font-semibold md:font-semibold";
