import CenterAlignedRoundLeftArrow from "../../Atoms/CarouselElements/CenterAlignedRoundLeftArrow/CenterAlignedRoundLeftArrow";
import CenterAlignedRoundRightArrow from "../../Atoms/CarouselElements/CenterAlignedRoundRightArrow/CenterAlignedRoundRightArrow";

export const SETTINGS = {
  variableWidth: true,
  dots: false,
  autoplay: true,

  cssEase: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  nextArrow: <CenterAlignedRoundRightArrow />,
  prevArrow: <CenterAlignedRoundLeftArrow />,
  arrows: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: "linear",
      },
    },
  ],
};

export const TESTIMONIAL_INNER_CONTAINER_ONE =
  "max-w-full lg:max-w-[70%]  mx-2 md:mx-auto  md:mb-10";
export const TESTIMONIAL_INNER_CONTAINER_TWO =
  "max-w-full lg:max-w-[80%] mx-auto my-6";
export const STYLIST_ENDPOINT = "/stylist/";
export const MEET_OUR_STYLIST = "meet-our-stylist";
export const MEET_OUR_STYLIST_BUTTON_ID_TEXT =
  "meet-our-stylist-section-button";
