import React from "react";
import NewAccordion from "../../Atoms/NewAccordion";
import Playfair40pxSemibold from "../../Atoms/Typography/Playfair40pxSemibold";
import { FAQ_SLICE_CONTAINER, QUESTIONS_CONTAINER } from "./styles";
import { FAQ_ID } from "./constants";
import NewPrimaryButton from "../../Atoms/Buttons/NewPrimaryButton/NewPrimaryButton";
import { WHATSAPP_SLEEK_FLOW } from "../../Atoms/WhatsApp/constants";

const ProductDetailsSlice = ({ slice, currentVariant }) => {
  const phoneNumber = WHATSAPP_SLEEK_FLOW;
  const message = `I’ve been checking out the Serena label, please can you send me the ${currentVariant?.title} ${currentVariant?.variant} in my next box!`;
  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    message
  )}`;
  return (
    <div className={FAQ_SLICE_CONTAINER}>
      <Playfair40pxSemibold text={slice?.primary?.title} />
      <div className={QUESTIONS_CONTAINER}>
        {slice?.items?.map((item, index) => (
          <NewAccordion
            question={item?.heading}
            answer={item?.content}
            id={index + FAQ_ID}
            image={item?.answer_image}
            key={index + FAQ_ID}
          />
        ))}
      </div>
      <NewPrimaryButton
        text={"CHAT WITH STYLIST"}
        className={"lg:w-fit w-full justify-center "}
        href={url}
      />
    </div>
  );
};

export default ProductDetailsSlice;
