import React, { useEffect, useState } from "react";
import ImageAtom from "../ImageAtom/ImageAtom";
import {
  BlackPlusIcon,
  ArrowDownPlaneEnds,
  MinusIcon,
} from "../../../public/Theme";
import TypographyMontserrat from "../TypographyMontserrat/";
import {
  ACCORDION_DETAILS,
  ACCORDION_EXPANDED_DATA,
  ACCORDION_SUMMARY,
  IMAGE_CONTAINER,
  ARROW_CONTAINER,
  QUESTION_TEXT_CONTAINER,
  ARROW_CLASS,
  ARROW_ROTATE,
  ACCORDION_CLOSE_TRANSITION,
  ACCORDION_OPEN_TRANSITION,
  HandleToggle,
} from "./constants";

const NewAccordion = ({ question, answer, id, image, open, accordionIcon }) => {
  const [accordionOpen, setAccordionOpen] = useState(id && open ? id : false);
  const icons =
    accordionIcon === "arrow"
      ? ArrowDownPlaneEnds
      : accordionOpen === id
      ? MinusIcon
      : BlackPlusIcon;
  return (
    <div
      className={ACCORDION_DETAILS}
      onClick={() => {
        HandleToggle(id, accordionOpen, setAccordionOpen);
      }}
    >
      <div className={ACCORDION_SUMMARY}>
        <div className={QUESTION_TEXT_CONTAINER}>
          <TypographyMontserrat
            align={"left"}
            text={question}
            fontWeight={"normal"}
            size={"12px"}
            color={"New_Primary_Default"}
          />
        </div>
        <div className={ARROW_CONTAINER}>
          <img
            className={
              ARROW_CLASS + (accordionOpen === id ? ARROW_ROTATE : null)
            }
            src={icons}
          />
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={
          ACCORDION_EXPANDED_DATA +
          (accordionOpen === id
            ? ACCORDION_OPEN_TRANSITION
            : ACCORDION_CLOSE_TRANSITION)
        }
      >
        <TypographyMontserrat
          align={"left"}
          children={typeof answer !== "string" ? answer : undefined}
          text={typeof answer === "string" ? answer : undefined}
          fontWeight={"light"}
          size={"10px"}
          color={"New_Primary_Default"}
        />
        <div className={IMAGE_CONTAINER}>
          {image ? <ImageAtom image={image} /> : null}
        </div>
      </div>
    </div>
  );
};

export default NewAccordion;
