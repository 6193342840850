import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { MONTSERRAT_XS_NORMAL } from "./constants";

const MontserratXsNormal = ({ text, simpleText, alignment, customStyle }) => {
  const textComponent = !simpleText ? (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={MONTSERRAT_XS_NORMAL + customStyle}>{children}</p>
        ),
        paragraph: ({ children }) => (
          <p className={MONTSERRAT_XS_NORMAL + customStyle}>{children}</p>
        ),
      }}
    ></PrismicRichText>
  ) : (
    <p className={MONTSERRAT_XS_NORMAL + customStyle}>{simpleText}</p>
  );
  return textComponent;
};

export default MontserratXsNormal;
