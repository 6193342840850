import React from "react";

import {
  BACKGROUND_IMAGE_CONTAINER_CLASS,
  BREAK_POINT_WIDTH,
  HERO_IMAGE_CONTAINER,
} from "./constants";
import EcommerceHeroText from "../../Atoms/Typography/EcommerceHeroText";
import {
  ORDER_A_LOOK,
  WIDTH_200_PX,
} from "../../../public/Constants/EnumConstants";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import PrimaryButtonECommerceOnly from "../../Atoms/Buttons/PrimaryButtonECommerceOnly";

const ECommerceBannerSection = ({ data }) => {
  const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);
  return (
    <div
      className={BACKGROUND_IMAGE_CONTAINER_CLASS}
      style={{
        backgroundImage: isBreakpoint
          ? `url(${data?.mobile_banner_image?.url})`
          : `url(${data?.banner_image?.url})`,
      }}
    >
      <div className={HERO_IMAGE_CONTAINER}>
        <EcommerceHeroText text={data?.title} />
        <PrimaryButtonECommerceOnly
          text={data?.button_text?.[0]?.text}
          loader={data?.loader}
          href={`${process.env.OLD_WEB_URL}${ORDER_A_LOOK}`}
        />
      </div>
    </div>
  );
};

export default ECommerceBannerSection;
