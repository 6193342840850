import React from "react";
import { FailedIcon } from "../../../../public/Theme";

import {
  MODAL_BODY,
  MODAL_BODY_TEXT_CLASS,
  MODAL_CONTAINER,
  MODAL_CONTENT_CONTAINER,
  MODAL_SUB_CONTAINER,
  ERROR_ICON,
  ERROR_TEXT,
} from "./constants";

const ErrorToast = () => {
  return (
    <>
      <div className={MODAL_CONTAINER}>
        <div className={MODAL_SUB_CONTAINER}>
          <div className={MODAL_CONTENT_CONTAINER}>
            <div className={MODAL_BODY}>
              <img
                className={ERROR_ICON}
                src={FailedIcon}
                alt={"Error Icon"}
                loading="lazy"
              />
              <p className={MODAL_BODY_TEXT_CLASS}>{ERROR_TEXT}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorToast;
