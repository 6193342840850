export const settings = {
  dots: true,
  slidesToShow: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: "linear",
  infinite: true,
  arrows: false,
};
