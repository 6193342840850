import React from "react";
import Slider from "react-slick";
import dynamic from "next/dynamic";
import { settings } from "./constants";
import NewCustomTestimonialDots from "../../Atoms/CarouselElements/NewCustomTestimonialDots";
import {
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_THREE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./Style";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const NewTestimonialCard = dynamic(() =>
  import("../../Molecules/NewTestimonialCard")
);
const SessionDescription = dynamic(() =>
  import("../../Atoms/Typography/SessionDescription")
);
const NewTestimonialsSection = ({ data }) => {
  return (
    <div>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        <SessionDescription text={data?.primary?.description} />
      </div>

      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <div className={TESTIMONIAL_INNER_CONTAINER_THREE}>
          <Slider
            {...settings}
            customPaging={(i) => (
              <NewCustomTestimonialDots
                src={data?.items?.[i]?.clients_image?.url}
              />
            )}
          >
            {data?.items?.map((item, index) => {
              return (
                <NewTestimonialCard
                  key={index}
                  image={item?.clients_image}
                  description={item?.review}
                  short_text={item?.short_text}
                  client_name={item?.client_name}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default NewTestimonialsSection;
