import React from "react";
import {
  CONTAINER_CLASS,
  IMAGE_CLASS,
  INNER_CONTAINER_CLASS,
  TEXT_CONTAINER_CLASS,
} from "./constants";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import SectionHeading from "../../Atoms/Typography/SectionHeading";
import ParagraphLight from "../../Atoms/Typography/ParagraphLight";
import {
  SIGN_UP_ENDPOINT,
} from "../../../public/Constants/EnumConstants";
import MontserratLableMedium from "../../Atoms/Typography/MontserratLableMedium";
import MontserratParagraphItalic from "../../Atoms/Typography/MontserratParagraphItalic";
import MontserratParagraphMedium from "../../Atoms/Typography/MontserratParagraphMedium";
import { PrismicImage } from "@prismicio/react";

const StylistBio = ({ data }) => {
  return (
    <div className={CONTAINER_CLASS}>
      <div>
        {/* //COME BACK TO */}
        <PrismicImage
          field={data?.primary.stylist_image}
          className={IMAGE_CLASS}
        />
        <div className={INNER_CONTAINER_CLASS}>
          <SectionHeading text={data?.primary?.name} />
          <ParagraphLight text={data?.primary?.instagram_handle} />
        </div>
      </div>
      <div className={TEXT_CONTAINER_CLASS}>
        <MontserratParagraphItalic text={data?.primary?.stylist_style} />
        <br />
        <MontserratLableMedium
          text={data?.primary?.about_me_label}
        ></MontserratLableMedium>
        <MontserratParagraphMedium text={data?.primary?.little_about_me} />
        <MontserratLableMedium text={data?.primary?.personal_style_label} />
        <MontserratParagraphMedium text={data?.primary?.personal_style} />
        <MontserratLableMedium text={data?.primary?.insider_info_label} />
        <MontserratParagraphMedium text={data?.primary?.insider_info} />
        <br />
        <br />
        <PrimaryButton
          text={data?.primary?.button_text}
          href={SIGN_UP_ENDPOINT}
        ></PrimaryButton>
      </div>
    </div>
  );
};

export default StylistBio;
