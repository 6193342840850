import { ENGLISH } from "../../../public/Constants/EnumConstants";

export const POSITION_RELATIVE = "grid  sm:grid-cols-2  ";
export const HERO_VIDEO_CONTAINER =
  "basis-full sm:basis-1/2 relative order-last sm:order-first";
export const HERO_DESKTOP_VIDEO_CLASS = " w-[100%] h-auto";
export const HERO_MOBILE_VIDEO_CLASS = "sm:hidden w-[100%] h-auto";
export const HERO_CONTENT_CONTAINER =
  "basis-full sm:basis-1/2 sm:bg-[#F5E4E9] text-center  bg-opacity-60 flex justify-center items-center ";

export const HERO_CONTENT_INNER_CONTAINER =
  " my-16 mx-3  !text-center xl:!max-w-[95%]";
export const HERO_CONTENT_INNER_CONTAINER_AR =
  " my-16 mx-3 sm:ml-[13%] !text-center md:!text-center xl:!max-w-[95%]";
export const HERO_DESCRIPTION_CONTAINER = "";
export const HERO_BANNER_CTA_CLASS =
  "!bg-black hover:!bg-LIGHT_BLACK !text-white";

export const BANNER_BUTTON_ID_TEXT = "hero-banner-section-button";

export const CONTROLS_CONTAINER =
  "absolute top-0 right-0 left-0 bottom-0 flex flex-col my-auto justify-between cursor-pointer";
export const PLAY_BUTTON_CONTAINER =
  "flex-col items-center justify-center h-max w-max m-auto";
export const VIDEO_OVERLAY = "opacity-80";
export const PLAY_BUTTON_ICON = "h-full w-full";
export const TEXT_6XL = "!text-4xl lg:!text-6xl !font-extrabold !my-0 lg:!my-3";
export const TEXT_5XL =
  "!text-3xl lg:!text-5xl !my-0 lg:!my-3 !font-semibold !uppercase sm:!capitalize";
export const WEAR_THAT_LOGO_CLASS =
  "mb-8 sm:mb-2 xl:mb-6 px-[25%] w-full h-full ";

export const WEAR_THAT_TEXT = "wear that";

export const getInnerContainerText = (locale) => {
  return locale === ENGLISH
    ? HERO_CONTENT_INNER_CONTAINER
    : HERO_CONTENT_INNER_CONTAINER_AR;
};

export const BANNER_PLACEHOLDER =
  "https://images.prismic.io/smartwebdev/66c313e2-f9e6-4676-a2b1-5e0b2df44dcb_New+Banner+Placeholder.webp?auto=compress,format";
