export const CARD_SPAN_CLASS = "max-w-xs px-2 mb-[12px] md:mb-auto ";
export const CARD_CONTAINER_CLASS =
  "flex flex-col lg:flex-row basis-0 grow items-start ";

export const CARD_FLEX_CLASS =
  "flex flex-col justify-center w-[90%] mx-auto lg:mx-[13px]";

export const HEADING_CONTAINER =
  "flex flex-row justify-center items-center gap-x-2 h-6 md:h-10";
export const HEADING_ICON = "max-w-[24px] ";
export const PERKS_ICON = "max-w-[120px]";
export const DESCRIPTION = "mt-[12px] md:mt-[4px] text-center pb-[28px]";
export const TOOL_TIP_COLOR = "Tooltip";
