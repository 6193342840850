import React from "react";
import dynamic from "next/dynamic";
import "./StylistWhatPeopleAreSaying.css";

import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { PrismicRichText } from "@prismicio/react";

const StylistAboutMeCard = dynamic(() =>
  import("../../Molecules/StylistAboutMeCard")
);

import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";

const StylistWhatPeopleAreSaying = ({ slice }) => {
  const breakpoint = useMediaQuery("770");
  const stylistCard = breakpoint
    ? slice?.primary?.stylist_card_mobile?.url
    : slice?.primary?.stylist_card?.url;

  return (
    <div className={"wrapper-container"}>
      <div className={"heading-container"}>
        <PrismicRichText
          field={slice?.primary?.title}
          components={{
            heading1: ({ children }) => (
              <p
                className={
                  "font-Montserrat font-semibold text-[22px] xs:text-2xl sm:text-[29px] lg:text-[32px]"
                }
              >
                {children}
              </p>
            ),
          }}
        ></PrismicRichText>
      </div>
      <div className={" flex flex-col lg:flex-row gap-4 mt-3 "}>
        {slice?.items?.map((item, index) => (
          <StylistAboutMeCard
            key={index}
            index={index}
            step={item?.index}
            heading={item?.title}
            description={item?.description}
            image={item?.image}
            cardStyle=" flex-1 lg:border-b-0 even:lg:border-l even:lg:px-12 even:lg:pr-1 even:lg:pl-8"
            descriptionStyle={" !leading-4 sm:!leading-6 sm:mt-3 "}
          />
        ))}
      </div>
      <div className={" button-container "}>
        <NewPrimaryButtonWrapper
          text={slice?.primary?.button_text}
          href={SIGN_UP_ENDPOINT}
          section={SURVEY_START_SECTIONS?.YourStyleYourWay}
          buttonId={"how-it-works-section-button"}
        />
      </div>
    </div>
  );
};

export default StylistWhatPeopleAreSaying;
