import NewCenterAlignedRoundLeftArrow from "../../Atoms/CarouselElements/NewCenterAlignedRoundLeftArrow";
import NewCenterAlignedRoundRightArrow from "../../Atoms/CarouselElements/NewCenterAlignedRoundRightArrow";

export const SETTINGS = {
  variableWidth: true,
  dots: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  slidesToShow: 4,
  rows: 2,
  slidesToScroll: 1,
  cssEase: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  nextArrow: <NewCenterAlignedRoundRightArrow />,
  prevArrow: <NewCenterAlignedRoundLeftArrow />,
  arrows: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2,
        rows: 3,
        slidesToScroll: 2,
      },
    },
  ],
};

export const TESTIMONIAL_INNER_CONTAINER_ONE =
  "max-w-full lg:max-w-[52rem]  mx-2 md:mx-auto ";
export const TESTIMONIAL_INNER_CONTAINER_TWO =
  " new-home-slick-dot custom-slick-stylist max-w-full mx-auto";
export const STYLIST_ENDPOINT = "/stylist/";
export const MEET_OUR_STYLIST = "meet-our-stylist";
export const MEET_OUR_STYLIST_BUTTON_ID_TEXT =
  "meet-our-stylist-section-button";
export const DISCRIPTION_CLASS = " mt-2 ";
export const SLIDER_CLASS = "w-full";
