"use client";
import React from "react";
import { PrismicRichText } from "@prismicio/react";
import AmbassadorBannerSection from "../../components/Molecules/AmbassadorBannerSection";

const BecomeAnAmbassadorBanner = ({ slice }) => (
  <section>
    <AmbassadorBannerSection data={slice?.primary} />
    <style jsx>{`
      section {
        margin: auto;
        max-width: 1440px;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: auto;
        }
      }
    `}</style>
  </section>
);

export default BecomeAnAmbassadorBanner;
