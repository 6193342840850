import React from "react";
import LatestDropsSection from "../../components/Organisms/LatestDropsSection";

const LatestDrops = ({ slice }) => {
  return (
    <section>
      {<LatestDropsSection data={slice} />}
      <style jsx>{`
        section {
          margin: 6em auto;
          text-align: right;
        }
        @media screen and (max-width: 500px) {
          section {
            margin: 3em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default LatestDrops;
