import React, { useState } from "react";
import ImageAtom from "../ImageAtom/ImageAtom";
import { BlackPlusIcon, MinusIcon } from "../../../public/Theme";
import TypographyMontserrat from "../TypographyMontserrat/";
import {
  ACCORDION_DETAILS,
  ACCORDION_EXPANDED_DATA,
  ACCORDION_SUMMARY,
  IMAGE_CONTAINER,
  ARROW_CONTAINER,
  QUESTION_TEXT_CONTAINER,
  ARROW_CLASS,
  ARROW_ROTATE,
  ACCORDION_CLOSE_TRANSITION,
  ACCORDION_OPEN_TRANSITION,
  HandleToggle,
} from "./constants";
const AccordionForAdditionalInfo = ({ question, answer, id, image, open }) => {
  const [accordionOpen, setAccordionOpen] = useState(id && open ? id : false);

  return (
    <div
      className={ACCORDION_DETAILS}
      onClick={() => {
        HandleToggle(id, accordionOpen, setAccordionOpen);
      }}
    >
      <div className={ACCORDION_SUMMARY}>
        <div className={QUESTION_TEXT_CONTAINER}>
          <TypographyMontserrat
            align={"left"}
            text={question}
            fontWeight={"normal"}
            size={"12px"}
            color={"New_Primary_Default"}
          />
        </div>
        <div className={ARROW_CONTAINER}>
          <img
            className={
              ARROW_CLASS + (accordionOpen === id ? ARROW_ROTATE : null)
            }
            src={accordionOpen === id ? MinusIcon : BlackPlusIcon}
          />
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={
          ACCORDION_EXPANDED_DATA +
          (accordionOpen === id
            ? ACCORDION_OPEN_TRANSITION
            : ACCORDION_CLOSE_TRANSITION)
        }
      >
        {answer}
        <div className={IMAGE_CONTAINER}>
          {image ? <ImageAtom image={image} /> : null}
        </div>
      </div>
    </div>
  );
};

export default AccordionForAdditionalInfo;
