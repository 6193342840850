import React from "react";
import dynamic from "next/dynamic";
import NewBannerImage from "../NewBannerImage";
import {
  BANNER_BUTTON_ID_TEXT,
  POSITION_RELATIVE,
  HERO_CONTENT_CONTAINER,
  TAG_LINE_CLASS,
  BANNER_IMAGE_CLASS,
  BUTTON_CLASS,
  HERO_CONTENT_INNER_CONTAINER,
} from "./constants";

import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";

import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

const NewHeroText = dynamic(() => import("../../Atoms/Typography/NewHeroText"));
const NewHeroDescription = dynamic(() =>
  import("../../Atoms/Typography/NewHeroDescription")
);
const NewPrimaryButton = dynamic(() =>
  import("../../Atoms/Buttons/NewPrimaryButton")
);

const NewBannerSection = ({ data }) => {
  const breakpoint = useMediaQuery("768");
  const ImageURL = !breakpoint
    ? data?.banner_image?.url
    : data?.mobile_banner_image?.url;

  return (
    <div className={POSITION_RELATIVE}>
      <img className={BANNER_IMAGE_CLASS} src={ImageURL} />
      <div className={HERO_CONTENT_CONTAINER}>
        <div className={HERO_CONTENT_INNER_CONTAINER}>
          <NewHeroDescription text={data?.description} />
          <NewHeroText text={data?.title} />
          <NewHeroDescription
            text={data?.tag_line}
            customStyles={TAG_LINE_CLASS}
          />
          <div className={BUTTON_CLASS}>
            <NewPrimaryButton
              text={data?.button_text}
              href={SIGN_UP_ENDPOINT}
              section={SURVEY_START_SECTIONS?.HomeBanner}
              buttonId={BANNER_BUTTON_ID_TEXT}
            />
          </div>
         
        </div>
      </div>
    </div>
  );
};

export default NewBannerSection;
