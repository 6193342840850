"use client";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import {
  SETTINGS,
  WRAPPER_CONTAINER,
  INNER_CONTAINER,
  MEDIA_CLASS,
} from "./Style";
import SerenaVideoPlayer from "../CustomVideoPlayer/SerenaVideoPlayer";

const SerenaCarousalWithVideo = ({ data }) => {
  const [sliderArray, setSliderArray] = useState({
    data: [],
    videoIndex: null,
  });

  useEffect(() => {
    const keys = Object?.keys(data || {});

    const newSliderArray = keys
      .filter((key) => key.includes("image") || key.includes("video"))
      .map((key) => data[key]);
    const videoIndex = newSliderArray?.findIndex(
      (item) => item?.kind === "document"
    );
    setSliderArray((prev) => ({
      ...prev,
      data: newSliderArray,
      videoIndex: videoIndex,
    }));
  }, [data]);

  return (
    <>
      {data && (
        <div className={WRAPPER_CONTAINER}>
          <div className={INNER_CONTAINER}>
            <Slider {...SETTINGS(sliderArray?.videoIndex)}>
              {sliderArray?.data?.map((item, index) => {
                if (item?.kind === "image") {
                  return (
                    <img
                      key={index}
                      src={item?.url}
                      alt={`Image ${index}`}
                      className={MEDIA_CLASS}
                    />
                  );
                } else if (item?.kind === "document") {
                  return (
                    <SerenaVideoPlayer
                      key={index}
                      videoUrl={item?.url}
                      autoplay
                      muted
                      videoPlaceHolder={sliderArray[0]?.url}
                      className={MEDIA_CLASS}
                      videoContainerClass={MEDIA_CLASS + " relative"}
                    />
                  );
                }
              })}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
};

export default SerenaCarousalWithVideo;
