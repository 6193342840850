import React from "react";
import { useRouter } from "next/navigation";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import dynamic from "next/dynamic";

import {
  ABOUT_US_ENDPOINT,
  BACKGROUND_IMAGE_CONTAINER_CLASS,
  getTextContainerClass,
  INNER_CONTAINER_ONE,
  TEXT_CONTAINER,
  TEXT_INNER_CONTAINER_ONE,
  TEXT_INNER_CONTAINER_TWO,
  WHO_WE_ARE_LEARN_MORE_ID,
} from "./constants";
import { WIDTH_IPAD_PRO } from "../../../public/Constants/EnumConstants";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const ImageAtom = dynamic(() => import("../../Atoms/ImageAtom"));
const WhoWeAreHeading = dynamic(() =>
  import("../../Atoms/Typography/WhoWeAreHeading")
);
const WhoWeAreDescription = dynamic(() =>
  import("../../Atoms/Typography/WhoWeAreDescription")
);
const PrimaryButton = dynamic(() =>
  import("../../Atoms/Buttons/PrimaryButton")
);

const WhoWeAreSection = ({ data }) => {
  const isBreakpoint = useMediaQuery(WIDTH_IPAD_PRO);
  const router = useRouter();

  return (
    <>
      <div className={INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.title} />
      </div>
      <div
        className={BACKGROUND_IMAGE_CONTAINER_CLASS}
        style={{
          backgroundImage: isBreakpoint
            ? `url(${data?.mobile_banner_image?.url})`
            : `url(${data?.banner_image?.url})`,
        }}
        loading="lazy"
      >
        <div className={getTextContainerClass(router?.locale)}>
          <WhoWeAreHeading
            text={data?.secondary_title}
            alignment={router?.locale}
          />
          <WhoWeAreDescription
            text={data?.description}
            alignment={router?.locale}
          />
          <div className={TEXT_INNER_CONTAINER_ONE}>
            <ImageAtom image={data?.signature_image} />
          </div>
        </div>
      </div>
      <div className={TEXT_INNER_CONTAINER_TWO}>
        <PrimaryButton
          text={data?.button_text}
          href={ABOUT_US_ENDPOINT}
          language={router?.locale}
          buttonId={WHO_WE_ARE_LEARN_MORE_ID}
        />
      </div>
    </>
  );
};

export default WhoWeAreSection;
