import NewCenterAlignedRoundLeftArrow from "../../Atoms/CarouselElements/NewCenterAlignedRoundLeftArrow";
import NewCenterAlignedRoundRightArrow from "../../Atoms/CarouselElements/NewCenterAlignedRoundRightArrow";

export const settings = {
  variableWidth: false,
  dots: true,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  slidesToShow: 2,
  slidesToScroll: 2,
  cssEase: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  nextArrow: <NewCenterAlignedRoundRightArrow />,
  prevArrow: <NewCenterAlignedRoundLeftArrow />,
  arrows: true,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 2,
        nextArrow: <NewCenterAlignedRoundRightArrow />,
        prevArrow: <NewCenterAlignedRoundLeftArrow />,
        arrows: true,
      },
    },
  ],
};
