import React from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";

const StylistProfileAdvice = ({ slice }) => {
  return (
    <div
      className={
        " flex flex-col lg:flex-row-reverse items-center mx-[17px] sm:mx-12 lg:mx-20 "
      }
    >
      <div className=" mb-5 ">
        <TypographyMontserrat
          children={slice?.primary?.title}
          fontWeight={"semibold"}
          customStyle={" !text-2xl sm:!text-[28px] mb-3 text-left "}
        />
        <TypographyMontserrat
          children={slice?.primary?.description}
          customStyle={
            " font-normal sm:font-light !text-sm sm:!text-[32px] sm:!leading-9 text-left "
          }
        />
      </div>
      <div>
        <img
          src={slice?.primary?.image?.url}
          alt=""
          className=" lg:min-w-[450px] xl:min-w-[628px] "
        />
      </div>
    </div>
  );
};

export default StylistProfileAdvice;
