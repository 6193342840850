"use client";
import React from "react";
import StyleAdviceSection from "../../components/Molecules/StyleAdviceSection";

/**
 * @typedef {import("@prismicio/client").Content.StyleAdviceSlice} StyleAdviceSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<StyleAdviceSlice>} StyleAdviceProps
 * @param { StyleAdviceProps }
 */
const StyleAdvice = ({ slice }) => (
  <section>
    <StyleAdviceSection data={slice} />
    <style jsx>{`
      section {
        margin: 4em auto;
        text-align: center;
      }
    `}</style>
  </section>
);

export default StyleAdvice;
