import React from "react";
import HowWeWorkSection from "../../components/Organisms/HowWeWorkSection/HowWeWorkSection";
import YourStyleYourWaySection from "../../components/Organisms/YourStyleYourWaySection";
import StylistLittleAboutMeSection from "../../components/Organisms/StylistLittleAboutMeSection";
import StylistWhatPeopleAreSaying from "../../components/Organisms/StylistWhatPeopleAreSaying";

const HowWeWork = ({ slice }) => {
  let renderSection = <HowWeWorkSection slice={slice} />;
  if (slice?.variation === "yourStyleYourWay") {
    renderSection = <YourStyleYourWaySection slice={slice} />;
  } else if (slice?.variation === "stylistAboutMe") {
    renderSection = <StylistLittleAboutMeSection slice={slice} />;
  } else if (slice?.variation === "whatPeopleAreSaying") {
    renderSection = <StylistWhatPeopleAreSaying slice={slice} />;
  }
  return (
    <section>
      {renderSection}

      <style jsx>{`
        section {
          margin: auto auto 6em auto;
          text-align: center;
          max-width: 1440px;
          display: flex;
          justify-content: center;
        }
        @media screen and (max-width: 500px) {
          section {
            margin-bottom: auto auto 5em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default HowWeWork;
