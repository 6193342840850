import React from "react";
import TestimonialsSection from "../../components/Organisms/TestimonialsSection/TestimonialsSection";
import NewTestimonialsSection from "../../components/Organisms/NewTestimonialsSection/NewTestimonialsSection";

const Testimonials = ({ slice }) => {
  let Variation = <TestimonialsSection data={slice} />;
  if (slice?.variation === "newTestimonials") {
    Variation = <NewTestimonialsSection data={slice} />;
  }
  return (
    <section>
      {Variation}
      <style jsx>{`
        section {
          // margin: 6em auto;
          text-align: right;
        }
        @media screen and (max-width: 500px) {
          section {
            // margin: 3em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default Testimonials;
