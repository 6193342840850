import React from "react";
import dynamic from "next/dynamic";

import {
  HOW_IT_WORKS_FIRST_ROW,
  HOW_IT_WORKS_SECOND_ROW,
  HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT,
  HOW_WE_WORK_MAIN_CONTAINER,
  HOW_WE_WORK_STYLE_CLASS,
  HOW_WE_WORK_TITLE,
} from "./constants";
import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { PrismicRichText } from "@prismicio/react";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";

const YourStyleYourWayCard = dynamic(() =>
  import("../../Molecules/YourStyleYourWayCard")
);


const YourStyleYourWaySection = ({ slice }) => {
  return (
    <div className={HOW_WE_WORK_MAIN_CONTAINER}>
      <div className={HOW_WE_WORK_STYLE_CLASS}>
        <PrismicRichText
          field={slice?.primary?.title}
          components={{
            heading1: ({ children }) => (
              <p className={HOW_WE_WORK_TITLE}>{children}</p>
            ),
          }}
        ></PrismicRichText>
      </div>
      <div className={HOW_IT_WORKS_FIRST_ROW}>
        {slice?.items?.map((item, index) => (
          <YourStyleYourWayCard
            key={index}
            index={index}
            step={item?.index}
            heading={item?.title}
            description={item?.description}
            image={item?.image}
          />
        ))}
      </div>
      <div className={HOW_IT_WORKS_SECOND_ROW}>
        <NewPrimaryButtonWrapper
          text={slice?.primary?.button_text}
          href={SIGN_UP_ENDPOINT}
          section={SURVEY_START_SECTIONS?.YourStyleYourWay}
          buttonId={HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT}
        />
      </div>
    </div>
  );
};

export default YourStyleYourWaySection;
