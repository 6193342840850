export const BANNER_CLASS =
  " w-full flex justify-center items-center bg-no-repeat bg-contain bg-center bg-transparent";
export const OVER_LAY_HEADING =
  "my-[145px] sm:my-[75px] md:my-[75px] lg:my-[145px] bg-[#FFFFFF80] h-max w-full justify-center  self-center font-Playfair font-bold text-[30px] md:text-[70px] text-center";
export const BANNER_HEADING = "font-Montserrat";
export const DESCRIPTION_CONTAINER =
  "w-[95%] md:w-[70%] justify-center self-center mx-auto mt-[4%]";
export const BANNER_DESCRIPTION =
  "text-center justify-center text-sm md:text-2xl";
export const BUTTON_CONTAINER = "flex justify-center mt-[2%]";

export const BREAK_POINT_WIDTH = 425;
export const FORM_LINK = "#registrationForm";
export const BANNER_CLASS_V2 = "my-4 md:my-7";
