import React from "react";
import Slider from "react-slick";
import dynamic from "next/dynamic";
import {
  DISCRIPTION_CLASS,
  MEET_OUR_STYLIST,
  SETTINGS,
  SLIDER_CLASS,
  STYLIST_ENDPOINT,
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./constants";

import TypographyMontserrat from "../../Atoms/TypographyMontserrat";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const SessionDescription = dynamic(() =>
  import("../../Atoms/Typography/SessionDescription")
);
const NewStylistCard = dynamic(() => import("../../Molecules/NewStylistCard"));

const StylistSection = ({ data }) => {
  return (
    <div id={MEET_OUR_STYLIST}>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        <SessionDescription text={data?.primary?.description} />
      </div>

      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <Slider {...SETTINGS} className={SLIDER_CLASS}>
          {data?.items?.map((item, index) => {
            return (
              <NewStylistCard
                key={index}
                index={index}
                image={item?.stylist_image}
                description={item?.stylist_style}
                instagram={item?.stylist_instagram}
                name={item?.stylist_name?.[0]?.text}
                link={STYLIST_ENDPOINT + item?.stylist_profile_link?.uid}
                profileButtonText={data?.primary?.view_profile_text}
                buttonText={data?.primary?.button_text?.[0]?.text}
                mobileButtonText={data?.primary?.mobile_button_text}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default StylistSection;
