"use client";
import React from "react";
import { PrismicRichText } from "@prismicio/react";
import ECommerceBannerSection from "../../components/Molecules/ECommerceBannerSection";

/**
 * @typedef {import("@prismicio/client").Content.ECommerceBannerSlice} ECommerceBannerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ECommerceBannerSlice>} ECommerceBannerProps
 * @param { ECommerceBannerProps }
 */
const ECommerceBanner = ({ slice }) => (
  <section>
    <ECommerceBannerSection data={slice?.primary} />
    <style jsx>{`
      section {
        margin: auto;
        text-align: center;
        max-width: 1440px;
      }
    `}</style>
  </section>
);

export default ECommerceBanner;
