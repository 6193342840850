import React from "react";
import {
  BlogBodyV1,
  BlogBodyV2,
  BlogBodyV3,
  BlogBodyV4,
  BlogBodyWithVideo,
} from "../../components/Molecules/BlogBody";

/**
 * @typedef {import("@prismicio/client").Content.BlogSectionSlice} BlogSectionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<BlogSectionSlice>} BlogSectionProps
 * @param { BlogSectionProps }
 */

const BlogBody = ({ variant, slice }) => {
  if (variant === "v2") return <BlogBodyV2 data={slice?.primary} />;
  if (variant === "v3") return <BlogBodyV3 data={slice?.primary} />;
  if (variant === "v4")
    return <BlogBodyV4 data={slice?.primary} items={slice?.items} />;
  if (variant === "blogWithVideos")
    return <BlogBodyWithVideo data={slice?.primary} items={slice?.items} />;
  return <BlogBodyV1 data={slice?.primary} />;
};

const BlogSection = ({ slice }) => (
  <section>
    <BlogBody variant={slice?.variation} slice={slice} />
    <style jsx>{`
      section {
        margin: 2em auto;
        text-align: left;
      }
    `}</style>
  </section>
);

export default BlogSection;
