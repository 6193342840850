"use client";
import React, { useState } from "react";
import Accordion from "../../Atoms/Accordion";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import FaqCategoryPill from "../FaqCategoryPill";
import {
  DESCRIPTION_CONTAINER,
  PILLS_CONTAINER,
  QUESTIONS_CONTAINER,
} from "./styles";
import { makeCategoriesObject } from "./constants";

const DashboardFaqSlice = ({ slice }) => {
  const faqsItems = slice?.items;
  const faqs = makeCategoriesObject(faqsItems, "category");
  const faqsCategories = Object?.keys(faqs);
  const [selectedCategory, setSelectedCategory] = useState(faqsCategories?.[0]);
  return (
    <div>
      <TypographyMontserrat
        fontWeight={"semibold"}
        size={"40px"}
        children={slice?.primary?.title}
      />
      <div className={DESCRIPTION_CONTAINER}>
        <TypographyMontserrat
          fontWeight={"normal"}
          size={"base"}
          children={slice?.primary?.description}
        />
      </div>
      <div className={PILLS_CONTAINER}>
        {faqsCategories?.map((category, index) => {
          return (
            <FaqCategoryPill
              category={category}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              key={index}
            />
          );
        })}
      </div>

      <div className={QUESTIONS_CONTAINER}>
        {faqs?.[selectedCategory]?.map((faq, index) => (
          <Accordion
            question={faq?.question}
            answer={faq?.answer}
            image={faq?.answer_image}
            id={index + selectedCategory}
            key={index + selectedCategory}
          />
        ))}
      </div>
    </div>
  );
};

export default DashboardFaqSlice;
