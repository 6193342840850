import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Montserrat_2XL_MEDIUM } from "./constants";

const Montserrat2XLMedium = ({ text, simpleText, alignment }) => {
  const textComponent = !simpleText ? (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={Montserrat_2XL_MEDIUM}>{children}</p>
        ),
        paragraph: ({ children }) => (
          <p className={Montserrat_2XL_MEDIUM}>{children}</p>
        ),
      }}
    ></PrismicRichText>
  ) : (
    <p className={Montserrat_2XL_MEDIUM}>{text}</p>
  );
  return textComponent;
};

export default Montserrat2XLMedium;
