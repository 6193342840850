import React from "react";

import { CARD_FLEX_CLASS, DESCRIPTION } from "./constants";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";
import MontserratBaseLight from "../../Atoms/Typography/MontserratBaseLight";
import MontserratBaseMedium from "../../Atoms/Typography/MontserratBaseMedium";

const HowItWorksCardVersion3 = ({ heading, description, image, index }) => {
  return (
    <div className={CARD_FLEX_CLASS} key={index}>
      <div className="flex justify-center items-center justify-items-center">
        <ImageAtom image={image} />
      </div>
      {heading?.[0]?.text ? <MontserratBaseMedium text={heading} /> : null}
      <div className={DESCRIPTION}>
        <MontserratBaseLight simpleText={description} />
      </div>
    </div>
  );
};

export default HowItWorksCardVersion3;
