export const MAIN_CONTAINER =
  "w-[343px] h-[663px] sm:w-[548px] sm:h-[490px]  bg-white flex justify-center items-center flex-col sm:flex-row relative";
export const CLOSE_BUTTON = "absolute top-2.5 right-2.5";
export const WEARTHAT_TEXT = "mt-8 mb-2";
export const IMAGE_CONTAINER = "w-[303px] h-[421px] sm:h-full sm:flex-1";
export const IMAGE = "h-full w-full object-cover";
export const CONTENT_CONTAINER =
  "h-full flex sm:flex-1 flex-col justify-between items-center";
export const WEARTHAT_WEB_TEXT = "mt-[40px]";
export const TITLE_DESCRIPTION_CONTAINER = "mt-2 mb-1";
export const TITLE = "text-lg sm:text-2xl font-semibold text-center";
export const DESCRIPTION =
  "text-sm sm:text-base font-medium leading-[24px] text-center";
export const BUTTON_CONTAINER = "flex mt-2";
export const FOOTER = "mb-10 mt-2 md:m-[17px]";
export const BUTTON_WIDTH = "w-[222px]";
export const IMAGE_ALT = "close Icon";
export const ANIMATE_FADE_IN = "animate-fadeIn";
export const ANIMATE_FADE_OUT = "animate-fadeOut";
export const FADE_IN = "fadeIn";
export const FADE_OUT = "fadeOut";
export const NONE = "none";
export const FLEX = "flex";
