import React from "react";
import dynamic from "next/dynamic";

import {
  BANNER_BUTTON_ID_TEXT,
  HERO_VIDEO_CONTAINER,
  POSITION_RELATIVE,
  HERO_CONTENT_CONTAINER,
  HERO_DESCRIPTION_CONTAINER,
  TEXT_5XL,
  getInnerContainerText,
  HERO_BANNER_CTA_CLASS,
  WEAR_THAT_LOGO_CLASS,
  WEAR_THAT_TEXT,
} from "./constants";

import {
  EAGER,
  LAYOUT_RESPONSIVE,
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { useRouter } from "next/navigation";
import { WTLogoEnglish } from "../../../public/Theme";

const HeroText = dynamic(() => import("../../Atoms/Typography/HeroText"));
const HeroDescription = dynamic(() =>
  import("../../Atoms/Typography/HeroDescription")
);
const PrimaryButton = dynamic(() =>
  import("../../Atoms/Buttons/PrimaryButton/PrimaryButton")
);
const ImageAtom = dynamic(() => import("../../Atoms/ImageAtom"));

const BannerSectionVariant3 = ({ data }) => {
  const router = useRouter();
  return (
    <div className={POSITION_RELATIVE}>
      <div className={HERO_VIDEO_CONTAINER}>
        <ImageAtom image={data?.banner_image} layout={LAYOUT_RESPONSIVE} />
      </div>
      <div className={HERO_CONTENT_CONTAINER}>
        <div className={getInnerContainerText(router?.locale)}>
          <img
            loading={EAGER}
            src={WTLogoEnglish}
            alt={WEAR_THAT_TEXT}
            className={WEAR_THAT_LOGO_CLASS}
          />
          <HeroText text={data?.title} className={TEXT_5XL} />
          <div className={HERO_DESCRIPTION_CONTAINER}>
            <HeroDescription text={data?.description} />
          </div>
          <br />
          <PrimaryButton
            text={data?.button_text}
            href={SIGN_UP_ENDPOINT}
            section={SURVEY_START_SECTIONS?.HomeBanner}
            buttonId={BANNER_BUTTON_ID_TEXT}
            className={HERO_BANNER_CTA_CLASS}
          />
        </div>
      </div>
    </div>
  );
};

export default BannerSectionVariant3;
