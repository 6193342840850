import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { ECOMMERCE_HERO_TITLE_CLASS } from "./constants";
import classNames from "classnames";

const EcommerceHeroText = ({ text, className }) => {
  const textClass = classNames(ECOMMERCE_HERO_TITLE_CLASS, className);
  return (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => <p className={textClass}>{children}</p>,
        paragraph: ({ children }) => <p className={textClass}>{children}</p>,
      }}
    ></PrismicRichText>
  );
};

export default EcommerceHeroText;
