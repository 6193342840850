import React from "react";
import { PRIMARY_BUTTON_CLASS } from "./constants";
import Link from "next/link";

const PrimaryButtonECommerceOnly = ({
  text,
  onClick,
  processing,
  width,
  href,
  className,
  style,
  type,
}) => {
  return href ? (
    (<Link
    href={href}
    passHref
    className={width + PRIMARY_BUTTON_CLASS + className}
    style={style}
    onClick={onClick}
    >

      {text}

    </Link>)
  ) : (
    <button
      type={type}
      className={width + PRIMARY_BUTTON_CLASS + className}
      style={style}
      onClick={onClick}
      disabled={processing}
    >
      {text}
    </button>
  );
};

export default PrimaryButtonECommerceOnly;
