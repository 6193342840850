export const WHATSAPP_ICON_CLASS = "z-30 fixed left-3 bottom-3.5 h-16 w-16";
export const WHATSAPP_IMAGE_CLASS = "h-full w-full";
export const WHATSAPP_URL = "https://wa.me/";
export const WHATSAPP_HUBSPOT = "971503249731";
export const WHATSAPP_SLEEK_FLOW = "971505283656";
export const WHATSAPP_INTERNATIONAL_DELIVERY = "971503274891";
export const WHATSAPP_URL_KSA =
  "https://api.whatsapp.com/send/?phone=971505283656&text=Hi%2C%20I%27m%20a%20new%20customer%20and%20I%27m%20ready%20to%20start%20my%20virtual%20styling%20experience&app_absent=0";
export const WHATSAPP_URL_NEW_SIGN_UP =
  "https://api.whatsapp.com/send/?phone=971505283656&text=Hi!%20I%20am%20your%20new%20SignUp!&app_absent=0";
