import React from "react";

import {
  CARD_CONTAINER_CLASS,
  LINE_IMAGE_CLASS,
  MAIN_CONTAINER,
} from "./constants";
import { Line } from "../../../public/Theme";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";
import SubHeading from "../../Atoms/Typography/SubHeading";
import ParagraphLight from "../../Atoms/Typography/ParagraphLight";
import SectionHeading from "../../Atoms/Typography/SectionHeading";

const OurValuesSection = ({ data }) => {
  return (
    <>
      <SectionHeading text={data?.primary?.title} />
      <div className={MAIN_CONTAINER}>
        {data?.items?.map((item, index) => (
          <div className={CARD_CONTAINER_CLASS} key={index}>
            <ImageAtom image={item?.value_icon} />
            <SubHeading text={item?.value} />
            <img
              src={Line}
              className={LINE_IMAGE_CLASS}
              alt={"Line Icon"}
              loading="lazy"
            />
            <ParagraphLight text={item?.description} />
          </div>
        ))}
      </div>
    </>
  );
};

export default OurValuesSection;
