import Image from "next/image";
import React from "react";
import { LeftArrow } from "../../../../public/Theme";

const CenterAlignedRoundLeftArrow = ({ className, style, onClick }) => {
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <Image fill alt="left arrow" src={LeftArrow} sizes="100vw" />
    </button>
  );
};

export default CenterAlignedRoundLeftArrow;
