import React from "react";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import {
  BULLET_SYMBOL,
  HYPERLINK_CLASS,
  MARGIN_BOTTOM_1,
  MARGIN_BOTTOM_3,
  MARGIN_BOTTOM_6,
  MARGIN_LEFT_4,
  Montserrat_XL_NORMAL,
  UNORDERED_LIST_ITEM,
} from "./constants";
import {
  ONE,
  LIST_STYLE_DISC,
} from "../../../../public/Constants/EnumConstants";

const MontserratXLNormal = ({ text, simpleText, alignment, className }) => {
  const textComponent = !simpleText ? (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={Montserrat_XL_NORMAL + className + MARGIN_BOTTOM_1}>
            {children}
          </p>
        ),
        heading5: ({ children }) => (
          <p className={Montserrat_XL_NORMAL + className + MARGIN_BOTTOM_1}>
            {children}
          </p>
        ),
        oList: ({ children }) => <ol>{children}</ol>,
        oListItem: ({ children }) => (
          <li
            type={ONE}
            className={
              Montserrat_XL_NORMAL + className + MARGIN_LEFT_4 + MARGIN_BOTTOM_3
            }
          >
            {children}
          </li>
        ),
        list: ({ children }) => <ul>{children}</ul>,
        listItem: ({ children }) => (
          <div className={UNORDERED_LIST_ITEM}>
            <p>{BULLET_SYMBOL}</p>
            <li
              className={
                Montserrat_XL_NORMAL +
                className +
                MARGIN_LEFT_4 +
                MARGIN_BOTTOM_3
              }
            >
              {children}
            </li>
          </div>
        ),
        paragraph: ({ children }) => (
          <p className={Montserrat_XL_NORMAL + className + MARGIN_BOTTOM_6}>
            {children}
          </p>
        ),
        hyperlink: ({ children, node }) => (
          <PrismicLink field={node?.data} className={HYPERLINK_CLASS}>
            {children}
          </PrismicLink>
        ),
      }}
    ></PrismicRichText>
  ) : (
    <p className={Montserrat_XL_NORMAL + className}>{simpleText}</p>
  );
  return textComponent;
};

export default MontserratXLNormal;
