import Image from "next/image";
import React from "react";
import { RightArrow } from "../../../../public/Theme";
import { CUSTOM_DOTS_STYLE } from "./Style";
import { CUSTOM_DOTS_ALT } from "./Constant";

const NewCustomTestimonialDots = ({ className, style, onClick, src }) => {
  return (
    <button
      className={className}
      style={{ ...style, top: "30% !important" }}
      onClick={onClick}
    >
      <img alt={CUSTOM_DOTS_ALT} src={src} className={CUSTOM_DOTS_STYLE} />
    </button>
  );
};

export default NewCustomTestimonialDots;
