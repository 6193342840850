"use client";
import React, { useState } from "react";
import dynamic from "next/dynamic";

import {
 
  CARDS_CONTAINER,
  THIS_OR_THAT_INNER_CONTAINER_ONE,
  THIS_OR_THAT_INNER_CONTAINER_TWO,
  NEXT_STEP_TEXT,
  NEXT_STEP_CONTAINER,
  THIS_OR_THAT_SECTION_BUTTON_ID_TEXT,
  THAT_BUTTON_ID_TEXT,
  THIS_BUTTON_ID_TEXT,
} from "./constants";
import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";

const ThisOrThatCard = dynamic(() => import("../../Molecules/ThisOrThatCard"));
const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const PrimaryButton = dynamic(() =>
  import("../../Atoms/Buttons/PrimaryButton")
);
const ThisOrThatSubHeading = dynamic(() =>
  import("../../Atoms/Typography/ThisOrThatSubHeading")
);

const ThisOrThatSection = ({ data }) => {
  const [itemsCount, setItemsCount] = useState(0);
  const handleClick = () => {
    gtag("event", "this_or_that_click", {
      content_id: "ThisThatGame",
    });
    setItemsCount(itemsCount + 1);
  };
  return (
    <div>
      <div className={THIS_OR_THAT_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
      </div>
      {itemsCount < data?.items?.length ? (
        <div className={THIS_OR_THAT_INNER_CONTAINER_TWO}>
          <ThisOrThatSubHeading text={data?.primary?.description} />
          <div className={CARDS_CONTAINER}>
            <ThisOrThatCard
              onClick={handleClick}
              image={data?.items?.[itemsCount]?.this_image}
              text={data?.primary?.this_text}
              buttonId={THIS_BUTTON_ID_TEXT}
            />

            <ThisOrThatCard
              onClick={handleClick}
              image={data?.items?.[itemsCount]?.that_image}
              text={data?.primary?.that_text}
              buttonId={THAT_BUTTON_ID_TEXT}
            />
          </div>
        </div>
      ) : (
        <div className={NEXT_STEP_CONTAINER}>
          <p className={NEXT_STEP_TEXT}>{data?.primary?.next_step}</p>
          <PrimaryButton
            text={data?.primary?.button_text}
            href={SIGN_UP_ENDPOINT}
            section={SURVEY_START_SECTIONS.ThisOrThat}
            buttonId={THIS_OR_THAT_SECTION_BUTTON_ID_TEXT}
          />
        </div>
      )}
    </div>
  );
};

export default ThisOrThatSection;
