import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { Montserrat_LABLE_MEDIUM } from "./constants";

const MontserratLableMedium = ({ text }) => {
  return (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={Montserrat_LABLE_MEDIUM}>{children}</p>
        ),
        paragraph: ({ children }) => (
          <p className={Montserrat_LABLE_MEDIUM}>{children}</p>
        ),
      }}
    ></PrismicRichText>
  );
};

export default MontserratLableMedium;
