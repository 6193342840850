import { ENGLISH } from "../../../public/Constants/EnumConstants";
export const HOW_WE_WORK_WRAPPER_CONTAINER = " mt-[-80px] pt-20";
export const HOW_WE_WORK_MAIN_CONTAINER =
  "relative w-fit border-[1px] m-3 px-4 py-5 lg:py-12 border-New_Primary_Default ";
export const HOW_WE_WORK_STYLE_CLASS =
  "absolute w-[219px] sm:w-[319px] right-1/2 transform translate-x-1/2 -translate-y-[35px] lg:-translate-y-[65px] bg-white flex justify-center";
export const HOW_WE_WORK_TITLE =
  "font-Montserrat font-semibold text-[22px] xs:text-2xl sm:text-[32px]";
export const HOW_IT_WORKS_FIRST_ROW =
  " flex flex-col lg:flex-row  gap-4 lg:gap-10 ";
export const HOW_IT_WORKS_SECOND_ROW =
  " absolute w-max right-1/2 transform translate-x-1/2 lg:translate-y-[25px] bg-white flex justify-center ";
const BUTTON_TEXT = "Start The Style Quiz";
const BUTTON_TEXT_AR = "ابدأ اختبار الأسلوب";
export const getSectionButtonText = (locale) => {
  return locale === ENGLISH ? BUTTON_TEXT : BUTTON_TEXT_AR;
};

export const HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT =
  "how-it-works-section-button";
export const HOW_IT_WORKS = "how-it-works";
