import React from "react";
import { WearthatLoading } from "../../../public/Theme";

const WearthatLoader = () => {
  return (
    <div className="h-screen grid justify-center items-center">
      <img src={WearthatLoading} />
    </div>
  );
};

export default WearthatLoader;
