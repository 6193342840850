import React from "react";
import Slider from "react-slick";
import { useRouter } from "next/navigation";
import dynamic from "next/dynamic";

import {
  MEET_OUR_STYLIST,
  MEET_OUR_STYLIST_BUTTON_ID_TEXT,
  SETTINGS,
  STYLIST_ENDPOINT,
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./constants";
import {
  DEFAULT,
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
  TEXT_CENTER,
  VERSION_2,
} from "../../../public/Constants/EnumConstants";
import {
  getSectionButtonText,
  HOW_IT_WORKS_SECOND_ROW,
} from "../HowItWorksSection/constants";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const StylistCard = dynamic(() => import("../../Molecules/StylistCard"));
const PrimaryButton = dynamic(() =>
  import("../../Atoms/Buttons/PrimaryButton")
);
const SessionDescription = dynamic(() =>
  import("../../Atoms/Typography/SessionDescription")
);

const StylistSection = ({ data }) => {
  const router = useRouter();
  return (
    <div id={MEET_OUR_STYLIST}>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        {data?.variation === VERSION_2 && (
          <div className={TEXT_CENTER}>
            <SessionDescription text={data?.primary?.description} />

            <PrimaryButton
              text={data?.primary?.primary_button_text}
              href={SIGN_UP_ENDPOINT}
              section={SURVEY_START_SECTIONS.MeetOurStylists}
            />
          </div>
        )}
      </div>

      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <Slider {...SETTINGS} className={"w-full"}>
          {data?.items?.map((item, index) => {
            return (
              <StylistCard
                key={index}
                index={index}
                image={item?.stylist_image}
                description={item?.stylist_style}
                instagram={item?.stylist_instagram}
                name={item?.stylist_name?.[0]?.text}
                link={STYLIST_ENDPOINT + item?.stylist_profile_link?.uid}
                profileButtonText={data?.primary?.view_profile_text}
                buttonText={data?.primary?.button_text?.[0]?.text}
                mobileButtonText={data?.primary?.mobile_button_text}
                alignment={router?.locale}
              />
            );
          })}
        </Slider>
      </div>
      {data?.variation === DEFAULT && (
        <div className={HOW_IT_WORKS_SECOND_ROW}>
          <PrimaryButton
            text={getSectionButtonText(router?.locale)}
            href={SIGN_UP_ENDPOINT}
            section={SURVEY_START_SECTIONS.MeetOurStylists}
            buttonId={MEET_OUR_STYLIST_BUTTON_ID_TEXT}
          />
        </div>
      )}
    </div>
  );
};

export default StylistSection;
