import { PrismicRichText } from "@prismicio/react";
import React from "react";
import {
  RECIPIENT_DETAILS_FORM,
  GIFT_CARD_HEADING,
  RECEIVER_FIRST_NAME_FIELD,
  RECEIVER_LAST_NAME_FIELD,
  RECEIVER_EMAIL_FIELD,
  GIFT_CARD_AMOUNT_FIELD,
  MESSAGE_FIELD,
  TEXT_TYPE,
  EMAIL_TYPE,
  NUMBER_TYPE,
  RECIPIENT_DETAILS_FORM_SUB_CONTAINER,
  FIELDS_CONTAINER,
  FIELDS_ROW_CONTAINER,
  TEXT_AREA_CONTAINER,
} from "./constants";
import { InputField } from "../../Atoms/Inputs/InputField";
import TextArea from "../../Atoms/Inputs/TextArea";
import { DropdownField } from "../../Atoms/Inputs/DropdownField";
import { FOUR_HUNDRED } from "../../../public/Constants/EnumConstants";

const GiftRecipientDetailsForm = ({ register, errors, data, items }) => {
  const receiverFirstNameLabel = data?.receiver_first_name_label?.[0]?.text;
  const receiverFirstNamePlaceHolder =
    data?.receiver_first_name_placeholder?.[0]?.text;
  const receiverLastNameLabel = data?.receiver_last_name_label?.[0]?.text;
  const receiverLastNamePlaceHolder =
    data?.receiver_last_name_placeholder?.[0]?.text;
  const receiverEmailLabel = data?.receiver_email_label?.[0]?.text;
  const receiverEmailPlaceHolder = data?.receiver_email_placeholder?.[0]?.text;
  const giftCardAmountLabel = data?.gift_card_amount_label?.[0]?.text;
  const giftCardAmountPlaceHolder =
    data?.gift_card_amount_placeholder?.[0]?.text;
  const messageLabel = data?.message_for_recipient_label?.[0]?.text;
  const messagePlaceholder = data?.message_for_recipient_placeholder?.[0]?.text;
  let amountOptions = [];
  items?.map((item) => {
    amountOptions.push(item?.gift_card_amount_options);
  });
  let amountValues = [];
  items?.map((item) => {
    amountValues.push(item?.gift_card_amount_values);
  });
  return (
    <div className={RECIPIENT_DETAILS_FORM}>
      <div className={RECIPIENT_DETAILS_FORM_SUB_CONTAINER}>
        <PrismicRichText
          field={data?.gift_card_heading}
          components={{
            paragraph: ({ children }) => (
              <p className={GIFT_CARD_HEADING}>{children}</p>
            ),
          }}
        />
        <div className={FIELDS_CONTAINER}>
          <div className={FIELDS_ROW_CONTAINER}>
            <InputField
              register={register}
              field={RECEIVER_FIRST_NAME_FIELD}
              placeholder={receiverFirstNamePlaceHolder}
              label={receiverFirstNameLabel}
              errors={errors}
              type={TEXT_TYPE}
            />
            <InputField
              register={register}
              field={RECEIVER_LAST_NAME_FIELD}
              placeholder={receiverLastNamePlaceHolder}
              label={receiverLastNameLabel}
              errors={errors}
              type={TEXT_TYPE}
            />
          </div>
          <div className={FIELDS_ROW_CONTAINER}>
            <InputField
              register={register}
              field={RECEIVER_EMAIL_FIELD}
              placeholder={receiverEmailPlaceHolder}
              label={receiverEmailLabel}
              errors={errors}
              type={EMAIL_TYPE}
            />
            <DropdownField
              register={register}
              field={GIFT_CARD_AMOUNT_FIELD}
              label={giftCardAmountLabel}
              errors={errors}
              options={amountOptions}
              values={amountValues}
            />
          </div>
          <div className={TEXT_AREA_CONTAINER}>
            <TextArea
              customStyle={" !h-[46px] !pt-2"}
              countCharacters
              register={register}
              field={MESSAGE_FIELD}
              placeholder={messagePlaceholder}
              label={messageLabel}
              errors={errors}
              maxCharacterLength={FOUR_HUNDRED}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftRecipientDetailsForm;
