import { ENGLISH } from "../../../public/Constants/EnumConstants";

export const POSITION_RELATIVE = "relative";
export const HERO_VIDEO_CONTAINER = "relative h-auto";
export const HERO_MOBILE_VIDEO_CLASS = " w-[100%] h-auto";
export const HERO_CONTENT_CONTAINER =
  "absolute  min-h-[485px] top-0 left-0 h-[100%] w-[100%] text-center flex justify-center !items-center";
export const HERO_CONTENT_INNER_CONTAINER =
  "grid grid-cols-1 text-center bg-opacity-60 justify-center !items-center align-middle mx-[2%] sm:mx-[15%] md:mx-[16%] lg:mx-[15%] 2xl:mx-[25%]  md:my-[8%] xl:my-[12%] 2xl:my-[15%]  p-5 absolute md:static bottom-[90px] md:bottom-auto";
export const TAG_LINE_CLASS = "mx-4 not-italic";
export const BUTTON_CLASS = "mt-6 mb-5";
export const BANNER_IMAGE_CLASS = "w-full h-auto object-cover";
export const BANNER_BUTTON_ID_TEXT = "hero-banner-section-button";
export const BREAK_POINT_WIDTH = 768;
