"use client";
import React from "react";
import SectionHeading from "../../components/Atoms/Typography/SectionHeading";
import HowItWorksVersion2 from "../../components/Molecules/HowItWorksVersion2";
import HowItWorksVersion3 from "../../components/Molecules/HowItWorksVersion3";
import HowItWorksSection from "../../components/Organisms/HowItWorksSection";
import {
  SLICE_CONTAINER,
  VERSION_2,
  DEFAULT,
  HOW_IT_WORKS,
  VERSION_3,
} from "./constants";

const HowItWorks = ({ slice }) => {
  let sliceVariation = <h2>No Slice Data!</h2>;
  if (slice?.variation === DEFAULT) {
    sliceVariation = (
      <div id={HOW_IT_WORKS}>
        {slice?.primary?.title ? (
          <SectionHeading text={slice.primary.title} />
        ) : (
          <h2>No Section Heading</h2>
        )}
        <div className={SLICE_CONTAINER}>
          <HowItWorksSection slice={slice} />
        </div>
      </div>
    );
  } else if (slice?.variation === VERSION_2) {
    sliceVariation = (
      <div className={SLICE_CONTAINER}>
        <HowItWorksVersion2
          title={slice?.primary?.title}
          items={slice?.items}
        />
      </div>
    );
  } else if (slice?.variation === VERSION_3) {
    sliceVariation = (
      <div className={SLICE_CONTAINER}>
        <HowItWorksVersion3
          title={slice?.primary?.title}
          items={slice?.items}
        />
      </div>
    );
  } else {
  }
  return (
    <section>
      {}
      {sliceVariation}
      <style jsx>{`
        section {
          margin: 6em auto;
          text-align: right;
          position: relative;
          top: auto;
        }
        @media screen and (max-width: 500px) {
          section {
            margin: 3em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default HowItWorks;
