import React from "react";
import { MuteIcon, UnmuteIcon } from "../../../../public/Theme";

const VolumeIcon = ({ showButtons }) => {
  return (
    <>
      {showButtons?.muted ? (
        <img src={MuteIcon} className="w-8 h-8 lg:w-10 lg:h-10" />
      ) : (
        <img src={UnmuteIcon} className="w-8 h-8 lg:w-10 lg:h-10" />
      )}
    </>
  );
};

export default VolumeIcon;
