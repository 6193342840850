import React from "react";
import Slider from "react-slick";
import dynamic from "next/dynamic";
import { settings } from "./constants";
import {
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./Style";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const BrandsCards = dynamic(() => import("../../Molecules/BrandsCards"));
const SessionDescription = dynamic(() =>
  import("../../Atoms/Typography/SessionDescription")
);
const BrandSection = ({ data }) => {
  return (
    <div>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        <SessionDescription text={data?.primary?.description} />
      </div>

      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <Slider {...settings}>
          {data?.items?.map((item, index) => {
            return (
              <BrandsCards
                key={index}
                image={item?.artist_image}
                brandLogo={item?.logo}
              />
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default BrandSection;
