// Function for making object(of arrays) based on a specific
// property from array.
export const makeCategoriesObject = (array, property) => {
  let categories = {};
  array?.forEach((item) => {
    if (categories[item?.[property]] === undefined) {
      categories[item?.[property]] = [item];
    } else {
      categories[item?.[property]]?.push(item);
    }
  });
  return categories;
};

export const FAQ_ID = "faq";
export const DASHBOARD_FAQ_ID = "dashboardFaq";
