import React from "react";

import TimelineSection from "../../components/Molecules/TimelineSection";

/**
 * @typedef {import("@prismicio/client").Content.TimelineSlice} TimelineSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TimelineSlice>} TimelineProps
 * @param { TimelineProps }
 */
const Timeline = ({ slice }) => (
  <section>
    <TimelineSection data={slice?.primary} />

    <style jsx>{`
      section {
        margin: 4em auto;
        text-align: center;
      }
    `}</style>
  </section>
);

export default Timeline;
