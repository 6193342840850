import { ENGLISH, ARABIC } from "../../../../public/Constants/EnumConstants";
export const SUB_HEADING_STYLE =
  " text-center text-sm md:text-xl font-normal leading-tight tracking-tight font-Montserrat text-Heading_Black py-5 ";
const LEFT_STYLING_SESSION_DESCRIPTION_CLASS =
  " text-justify sm:text-left text-sm md:text-xl font-normal leading-tight tracking-tight font-Montserrat text-Heading_Black py-5  ";
const RIGHT_STYLING_SESSION_DESCRIPTION_CLASS =
  " text-justify sm:text-right text-sm md:text-xl font-normal leading-tight tracking-tight font-Montserrat text-Heading_Black py-5  ";

export const getStylingSessionDescriptionClass = (locale) => {
  return locale === ENGLISH
    ? LEFT_STYLING_SESSION_DESCRIPTION_CLASS
    : locale === ARABIC
    ? RIGHT_STYLING_SESSION_DESCRIPTION_CLASS
    : SUB_HEADING_STYLE;
};
