// "use client";
import React from "react";

import BannerSection from "../../components/Molecules/BannerSection";
import {
  BannerSectionVariant2,
  BannerSectionVariant3,
} from "../../components/Molecules/BannerSectionVariants";
import ReferAFriendBanner from "../../components/Molecules/ReferAFriendBanner";
import NewBannerSection from "../../components/Molecules/NewBannerSection/NewBannerSection";

const Banner = ({ slice }) => {
  let sliceVariation = "";
  if (slice?.variation === "v2") {
    sliceVariation = <ReferAFriendBanner data={slice?.primary} />;
  }
  if (slice?.variation === "v3SplitScreen") {
    sliceVariation = <BannerSectionVariant2 data={slice?.primary} />;
  }
  if (slice?.variation === "newBanner") {
    // sliceVariation = <NewBannerSection data={slice?.primary} />;
    sliceVariation = <BannerSection data={slice?.primary} />;
  }
  if (slice?.variation === "v4SplitScreenWithImage") {
    sliceVariation = <BannerSectionVariant3 data={slice?.primary} />;
  }
  return (
    <section>
      {sliceVariation}
      <style jsx>{`
        section {
          text-align: center;
          margin-bottom: 7.5em;
          
        }
        @media screen and (max-width: 500px) {
          section {
            margin-bottom: 5em auto;
          }
        }
      `}</style>
    </section>
  );
};
export default Banner;
