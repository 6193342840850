import * as prismic from "@prismicio/client";
import * as prismicNext from "@prismicio/next";
import sm from "./prod.sm.json";

/**
 * The project's Prismic repository name.
 */
export const repositoryName = prismic.getRepositoryName(sm.apiEndpoint);

/**
 * The project's Prismic Link Resolver. This function determines the URL for a given Prismic document.
 *
 * @type {prismic.LinkResolverFunction}
 */

export const linkResolver = (doc) => {
  if (doc.type === "home") {
    return `/`;
  } else if (doc.type === "article") {
    return `/blogs/${doc.uid}`;
  } else if (doc.type === "serena_prodcut") {
    return `/serena/${doc.uid}`;
  } else if (doc.type === "serena") {
    return `/serena`;
  } else if (doc.type === "blog_main_page") {
    return `/blogs`;
  } else if (doc.type === "stylist_profile") {
    return `/stylist/${doc.uid}`;
  } else if (doc.type === "gift_card") {
    return `/gift-card`;
  } else if (doc.type === "success_page") {
    return `/success/${doc.uid}`;
  } else if (doc.type === "about_us") {
    return `/about-us`;
  } else if (doc.type === "ambassador_program") {
    return `/ambassador-program`;
  } else if (doc.type === "styling_session") {
    return `/styling-session`;
  } else if (doc.type === "refer_a_friend") {
    return `/referral`;
  } else if (doc.type === "documents") {
    return `/documents/${doc.uid}`;
  } else if (doc.type === "faq_page") {
    return `/faqs`;
  } else {
    return `/`;
  }
};

// Update the routes array to match your project's route structure
/** @type {prismic.ClientConfig['routes']} **/
const routes = [
  {
    type: "home",
    path: "/",
    lang: "en-us",
  },
  {
    type: "home",
    path: "/ar-ae",
    lang: "ar-ae",
  },
  {
    type: "about_us",
    path: "/about-us",
    lang: "en-us",
  },
  {
    type: "about_us",
    path: "/ar-ae/about-us",
    lang: "ar-ae",
  },
];

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export const createClient = (config = {}) => {
  const client = prismic.createClient(sm.apiEndpoint, {
    //routes,
    ...config,
  });

  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });

  return client;
};
