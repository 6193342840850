import Image from "next/image";
import React from "react";
import { RightArrow } from "../../../../public/Theme";

const CenterAlignedRoundRightArrow = ({ className, style, onClick }) => {
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <Image fill alt="right arrow" src={RightArrow} sizes="100vw" />
    </button>
  );
};

export default CenterAlignedRoundRightArrow;
