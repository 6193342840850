import React from "react";
import Slider from "react-slick";
import dynamic from "next/dynamic";
import { settings } from "./constants";
import {
  TESTIMONIAL_BUTTON_CONTAINER,
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./Style";
import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { BANNER_BUTTON_ID_TEXT } from "../../Molecules/NewBannerSection/constants";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";

const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const FullyStyleInclusiveCard = dynamic(() =>
  import("../../Molecules/FullyStyleInclusiveCard")
);
const SessionDescription = dynamic(() =>
  import("../../Atoms/Typography/SessionDescription")
);
const NewFullyStyleInclusiveSection = ({ data }) => {
  return (
    <div>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        <SessionDescription text={data?.primary?.description} />
      </div>

      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <Slider {...settings}>
          {data?.items?.map((item, index) => {
            return (
              <FullyStyleInclusiveCard
                key={index}
                image={item?.image}
                mobileImage={item?.mobile_image}
                description={item?.description}
                title={item?.title}
              />
            );
          })}
        </Slider>
      </div>
      <div className={TESTIMONIAL_BUTTON_CONTAINER}>
        <NewPrimaryButtonWrapper
          text={data?.primary?.button_text}
          href={SIGN_UP_ENDPOINT}
          section={SURVEY_START_SECTIONS?.FullyStyleInclusive}
          buttonId={BANNER_BUTTON_ID_TEXT}
        />
      </div>
    </div>
  );
};

export default NewFullyStyleInclusiveSection;
