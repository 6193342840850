import React from "react";
import { PrismicRichText } from "@prismicio/react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton/PrimaryButton";
import {
  BUTTON_CONTAINER,
  BUTTON_WIDTH,
  CLOSE_BUTTON,
  CONTENT_CONTAINER,
  DESCRIPTION,
  FOOTER,
  IMAGE,
  IMAGE_ALT,
  IMAGE_CONTAINER,
  MAIN_CONTAINER,
  TITLE,
  TITLE_DESCRIPTION_CONTAINER,
  WEARTHAT_TEXT,
  WEARTHAT_WEB_TEXT,
} from "./styles";
import { CloseIcon } from "../../../public/Theme";
import { BUTTON, LAZY } from "../../../public/Constants/EnumConstants";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import { BUTTON_URL, CENTER, EXTRA_BOLD } from "./constants";

const PopUpLandingPage = ({ data, onClick }) => {
  const breakpoint = useMediaQuery("640");
  return (
    <div className={MAIN_CONTAINER}>
      <button type={BUTTON} onClick={onClick} className={CLOSE_BUTTON}>
        <img src={CloseIcon} alt={IMAGE_ALT} loading={LAZY} />
      </button>
      {breakpoint && (
        <TypographyMontserrat
          size={"2xl"}
          text={data?.wear_that_text}
          fontWeight={EXTRA_BOLD}
          customStyle={WEARTHAT_TEXT}
        />
      )}
      <div className={IMAGE_CONTAINER}>
        <img className={IMAGE} src={data?.image?.url} />
      </div>
      <div className={CONTENT_CONTAINER}>
        {!breakpoint && (
          <TypographyMontserrat
            size={"2xl"}
            text={data?.wear_that_text}
            fontWeight={EXTRA_BOLD}
            customStyle={WEARTHAT_WEB_TEXT}
          />
        )}
        <div className={TITLE_DESCRIPTION_CONTAINER}>
          <PrismicRichText
            field={data?.title}
            components={{
              heading1: ({ children }) => <p className={TITLE}>{children}</p>,
            }}
          />
          <PrismicRichText
            field={data?.description}
            components={{
              paragraph: ({ children }) => (
                <p className={DESCRIPTION}>{children}</p>
              ),
            }}
          />
          <div className={BUTTON_CONTAINER}>
            <PrimaryButton
              text={data?.button_text}
              href={BUTTON_URL}
              width={BUTTON_WIDTH}
            />
          </div>
        </div>
        <TypographyMontserrat
          size={"10px"}
          align={CENTER}
          text={data?.footer_text}
          customStyle={FOOTER}
        />
      </div>
    </div>
  );
};

export default PopUpLandingPage;
