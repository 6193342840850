import React from "react";
import { CalendarIcon } from "../../../../public/Theme";
import dynamic from "next/dynamic";
import {
  INPUT_CONTAINER,
  LABEL,
  INPUT_FIELD,
  INPUT_FIELD_ERROR,
} from "./constants";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const DynamicDatePicker = dynamic(() => import("react-date-picker"), {
  ssr: false,
});

const DatePickerField = ({
  value,
  label,
  field,
  errors,
  onChange,
  onBlur,
  minDate,
  maxDate,
  dateFormat,
}) => {
  const inputClassName = errors?.[field] ? INPUT_FIELD_ERROR : INPUT_FIELD;
  const firstAvailableDate = new Date(minDate);
  return (
    <div className={INPUT_CONTAINER}>
      <p className={LABEL}>{label}</p>
      <DynamicDatePicker
        className={inputClassName}
        closeCalendar
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        dropdownMode="scroll"
        clearIcon={null}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yy"
        calendarIcon={<img src={CalendarIcon} alt={"Calendar Icon"} />}
        minDate={minDate}
        maxDate={maxDate}
        format={dateFormat}
        defaultValue={firstAvailableDate}
      />
      <ErrorTextWithIcon text={errors?.[field]?.message} />
    </div>
  );
};

export default DatePickerField;
