import React, { useRef, useState } from "react";
import {
  VIDEO_OVERLAY,
  CONTROLS_CONTAINER,
  PLAY_BUTTON_CONTAINER,
  VIDEO_CLASS,
  MUTE_BUTTON_CONTAINER,
  VIDEO_CONTAINER,
} from "./styles";
import { Mute, VideoIcon, Unmute } from "../../../public/Theme";

const CustomVideoPlayer = ({
  autoplay,
  className,
  controls,
  muted,
  videoContainerClass,
  videoPlaceHolder,
  videoUrl,
}) => {
  const videoRef = useRef(null);
  const [showButtons, setShowButtons] = useState({
    play: !autoplay ? true : false,
    muted: muted,
  });
  return (
    <div className={videoContainerClass || VIDEO_CONTAINER}>
      <video
        ref={videoRef}
        playsInline={true}
        webkit-playsinline={"true"}
        disablePictureInPicture={true}
        loop={true}
        className={className || VIDEO_CLASS}
        preload="none"
        muted={showButtons?.muted}
        autoPlay={autoplay}
        controls={controls}
        poster={videoPlaceHolder}
        src={videoUrl}
      ></video>
      <div
        className={CONTROLS_CONTAINER}
        onClick={() => {
          if (videoRef?.current?.paused) {
            videoRef?.current?.play();
            videoRef?.current?.classList?.remove(VIDEO_OVERLAY);
            setShowButtons({ ...showButtons, play: false });
          } else {
            videoRef?.current?.pause();
            videoRef?.current?.classList?.add(VIDEO_OVERLAY);
            setShowButtons({ ...showButtons, play: true });
          }
        }}
      >
        <div className={PLAY_BUTTON_CONTAINER}>
          {showButtons?.play ? <img src={VideoIcon} /> : null}
        </div>

        <div />
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
