import { PrismicRichText } from "@prismicio/react";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import DatePickerField from "../../Atoms/Inputs/DatePickerField";
import { InputField } from "../../Atoms/Inputs/InputField";
import ProgressLoader from "../../Atoms/ProgressLoader";
import {
  DATE_FIELD,
  FIELDS_CONTAINER,
  FORM_CONTAINER,
  FORM_HEADING,
  PAYMENT_METHOD_HEADING,
  SENDER_EMAIL_FIELD,
  SENDER_FULL_NAME_FIELD,
  TIME_FIELD,
  FIELD_CONTAINER,
  BUTTON_CONTAINER,
  CARD_INFO_CONTAINER,
  CVV_EXPIRY_CONTAINER,
  CREDIT_CARD,
  APPLE_PAY,
  PAYMENT_METHOD_CONTAINER,
  RADIO_BUTTON,
  LABEL_CLASS,
} from "./constants";
import { Frames } from "frames-react";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import CreditCardNumberField from "../../Atoms/Inputs/CreditCardNumberField/CreditCardNumberField";
import CVVField from "../../Atoms/Inputs/CVVField/CVVField";
import CreditCardExpiryField from "../../Atoms/Inputs/CreditCardExpiryField/CreditCardExpiryField";
import {
  RADIO_TYPE,
  SUBMIT_TYPE,
  TIME_OPTIONS,
  WIDTH_200_PX,
} from "../../../public/Constants/EnumConstants";
import { TimeDropdownField } from "../../Atoms/Inputs/DropdownField";
import { LABEL } from "../../Atoms/Typography/InputLabel/InputLabelLight/constants";
import { useRouter } from "next/navigation";

const GiftSenderDetailsForm = ({
  control,
  register,
  errors,
  data,
  apiCall,
  isValid,
  watch,
}) => {
  const [paymentMethod, setPaymentMethod] = useState(CREDIT_CARD);
  const [payButtonDisabled, setPayButtonDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const onCardValidationChanged = (valid) => {
    const { isValid } = valid;
    setPayButtonDisabled(isValid);
  };
  const fullNameLabel = data?.sender_full_name_label?.[0]?.text;
  const emailLabel = data?.sender_email_label?.[0]?.text;
  const creditCardLabel = data?.credit_card_label?.[0]?.text;
  const applePayLabel = data?.apple_pay_label?.[0]?.text;
  const cardNumberLabel = data?.card_number_label?.[0]?.text;
  const expiryLabel = data?.expiry_label?.[0]?.text;
  const cvvLabel = data?.cvv_label?.[0]?.text;
  const dateLabel = data?.delivery_date_label?.[0]?.text;
  const timeLabel = data?.delivery_time_label?.[0]?.text;
  const today = new Date();
  const radioOnclick = (method) => {
    setPaymentMethod(method);
  };
  const { locale } = useRouter();
  const framesComponents = () => {
    return (
      <Frames
        config={{
          debug: true,
          publicKey: `${process.env.CHECKOUT_PUBLIC_KEY}`,
          localization: {
            cardNumberPlaceholder: "",
            expiryMonthPlaceholder: "",
            expiryYearPlaceholder: "",
            cvvPlaceholder: "",
          },
          style: {
            base: {
              fontSize: "16px",
            },
          },
        }}
        ready={() => {}}
        frameActivated={(e) => {}}
        frameFocus={(e) => {}}
        frameBlur={(e) => {}}
        frameValidationChanged={(e) => {}}
        paymentMethodChanged={(e) => {}}
        cardValidationChanged={(e) => onCardValidationChanged(e)}
        cardSubmitted={() => {}}
        cardTokenized={(e) => {
          apiCall({ token: e.token });
        }}
        cardTokenizationFailed={(e) => {}}
        cardBinChanged={(e) => {}}
      >
        <div className={CARD_INFO_CONTAINER}>
          <CreditCardNumberField label={cardNumberLabel} />
          <div className={CVV_EXPIRY_CONTAINER}>
            <CreditCardExpiryField label={expiryLabel} />
            <CVVField label={cvvLabel} />
          </div>
        </div>
        <div className={BUTTON_CONTAINER}>
          <PrimaryButton
            disabled={!payButtonDisabled}
            type={SUBMIT_TYPE}
            text={data?.buy_button?.[0]?.text}
            // processing={processing}
            onClick={() => {
              if (isValid) {
                Frames.submitCard();
                setProcessing(true);
              }
            }}
            width={WIDTH_200_PX}
          />
        </div>
      </Frames>
    );
  };

  return (
    <div className={FORM_CONTAINER}>
      {processing && <ProgressLoader />}
      <div className={FORM_HEADING}>
        <PrismicRichText
          field={data?.your_details_form_heading}
          components={{
            paragraph: ({ children }) => <p>{children}</p>,
          }}
        />
      </div>
      <div>
        <div className={FIELDS_CONTAINER}>
          <div className={FIELD_CONTAINER}>
            <InputField
              label={fullNameLabel}
              register={register}
              errors={errors}
              field={SENDER_FULL_NAME_FIELD}
            />
          </div>
          <div className={FIELD_CONTAINER}>
            <InputField
              label={emailLabel}
              register={register}
              errors={errors}
              field={SENDER_EMAIL_FIELD}
            />
          </div>
          <div className={FIELD_CONTAINER}>
            <Controller
              control={control}
              name={DATE_FIELD}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <DatePickerField
                  value={value}
                  label={dateLabel}
                  field={name}
                  errors={errors}
                  onChange={onChange}
                  onBlur={onBlur}
                  minDate={today}
                />
              )}
            />
          </div>
          <div className={FIELD_CONTAINER}>
            <TimeDropdownField
              register={register}
              label={timeLabel}
              field={TIME_FIELD}
              errors={errors}
              options={TIME_OPTIONS?.time}
              values={TIME_OPTIONS?.values}
              watch={watch}
              locale={locale}
            />
          </div>
        </div>
      </div>
      <div className={PAYMENT_METHOD_HEADING}>
        <PrismicRichText
          field={data?.payment_method_heading}
          components={{
            paragraph: ({ children }) => <p>{children}</p>,
          }}
        />
      </div>
      <div className={PAYMENT_METHOD_CONTAINER}>
        <div className={LABEL_CLASS}>
          <label className={LABEL}>
            <input
              name={CREDIT_CARD}
              type={RADIO_TYPE}
              checked={paymentMethod === CREDIT_CARD}
              onChange={() => radioOnclick(CREDIT_CARD)}
              className={RADIO_BUTTON}
            />
            {creditCardLabel}
          </label>
        </div>
        {paymentMethod === CREDIT_CARD && framesComponents()}
        {/* <input
          name={APPLE_PAY}
          type={RADIO_TYPE}
          checked={paymentMethod === APPLE_PAY}
          onChange={() => radioOnclick(APPLE_PAY)}
        />
        {paymentMethod === APPLE_PAY && "APPlE PAy"} */}
      </div>
    </div>
  );
};

export default GiftSenderDetailsForm;
