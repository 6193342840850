import React from "react";
import NewFullyStyleInclusiveSection from "../../components/Organisms/NewFullyStyleInclusiveSection";

const FullyStyleInclusiveSection = ({ slice }) => {
  return (
    <section>
      {<NewFullyStyleInclusiveSection data={slice} />}
      <style jsx>{`
        section {
          margin: 7.5em auto;
          text-align: right;
        }
        @media screen and (max-width: 500px) {
          section {
            margin: 5em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default FullyStyleInclusiveSection;
