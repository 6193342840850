import React from "react";
import { PrismicRichText } from "@prismicio/react";
import JournalSection from "../../components/Molecules/JournalSection";

/**
 * @typedef {import("@prismicio/client").Content.JournalsSectionSlice} JournalsSectionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<JournalsSectionSlice>} JournalsSectionProps
 * @param { JournalsSectionProps }
 */
const JournalsSection = ({ slice }) => (
  <section>
    <JournalSection data={slice?.primary} items={slice?.items} />
    <style jsx>{`
      section {
        margin: 7.5em auto;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: 5em auto;
        }
      }
    `}</style>
  </section>
);

export default JournalsSection;
