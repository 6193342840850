import React from "react";
import ProductDetailsSlice from "../../components/Molecules/ProductDetailsSlice";

/**
 * @typedef {import("@prismicio/client").Content.ProductDetailsSlice} ProductDetailsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ProductDetailsSlice>} ProductDetailsProps
 * @param { ProductDetailsProps }
 */
const ProductDetails = ({ slice, variant }) => (
  <section>
    <ProductDetailsSlice currentVariant={variant} slice={slice} />
  </section>
);

export default ProductDetails;
