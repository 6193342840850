import React from "react";
import { SELECTED_OPTION, SINGLE_OPTION_CONTAINER } from "./styles";

const VariantOption = ({ item, variant, setState }) => {
  return (
    <div
      className={
        SINGLE_OPTION_CONTAINER +
        (item?.color_title === variant?.variant ? SELECTED_OPTION : "")
      }
      onClick={() => setState(item)}
    >
      <img src={item?.color_svg?.url} alt={item?.color_svg?.alt} />
    </div>
  );
};

export default VariantOption;
