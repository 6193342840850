export const fontSize = {
  "10px": "text-[10px]",
  xs: "2xl:text-xs", //17
  sm: "2xl:text-sm", //18
  base: "2xl:text-base", //21
  lg: "2xl:text-lg", //24
  xl: "2xl:text-xl", //26
  "2xl": "2xl:text-2xl", //32
  "3xl": "2xl:text-3xl", //50
  "4xl": "2xl:text-4xl",
  "6xl": "2xl:text-6xl",
  "40px": "2xl:text-[40px]", //40
};

export const fontSizeLg = {
  "10px": "text-[10px]",
  xs: "lg:text-xs", //17
  sm: "lg:text-xs", //18
  base: "lg:text-sm", //21
  lg: "lg:text-lg", //24
  xl: "lg:text-xl", //26
  "2xl": "lg:text-2xl", //32
  "3xl": "lg:text-3xl", //50
  "4xl": "lg:text-4xl",
  "6xl": "lg:text-6xl",
  "40px": "lg:text-[40px]", //40
};

export const fontSizeSm = {
  "10px": "text-[10px]",
  xs: "text-xs", //17
  sm: "text-xs", //18
  base: "text-sm", //21
  lg: "text-base", //24
  xl: "text-base", //26
  "2xl": "text-xl", //24
  "3xl": "text-2xl", //26
  "4xl": "text-2xl",
  "6xl": "text-3xl",
  "40px": "text-2xl", //40
};
export const fontType = {
  normal: "font-normal",
  thin: "font-thin",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
};

export const textAlign = {
  left: "text-left",
  right: "text-right",
  center: "text-center",
};

export const textDecoration = {
  underline: "underline",
  overline: "overline",
  lineThrough: "line-through",
  noUnderLine: "no-underline",
};

export const textColors = {
  Primary_Default: "text-Primary_Default",
  Text_Gray: "text-Text_Gray",
  Text_Gray2: "text-Text_Gray2",
  Text_Grey3: "text-Text_Grey3",
  Stroke_Grey: "text-Stroke_Grey",
  White: "text-white",
  inherit: "text-inherit",
  red: "text-Error_Default",
  green: "text-Selected_State_Color",
};
