import React from "react";
import Slider from "react-slick";
import { useRouter } from "next/navigation";
import dynamic from "next/dynamic";

import {
  getSectionButtonText,
  HOW_IT_WORKS_SECOND_ROW,
} from "../../Organisms/HowItWorksSection/constants";
import {
  IMAGE_BACKGROUND_STYLE,
  MOBILE_SCREEN,
  ORDER_A_LOOK,
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
  VERSION_2,
} from "../../../public/Constants/EnumConstants";
import {
  settings,
  HOW_TO_WEAR_TAHT_INNER_CONTAINER_ONE,
  HOW_TO_WEAR_TAHT_INNER_CONTAINER_TWO,
  TEXT_ALIGNMENT_CLASS_WITH_PADDING_TOP,
  HOW_TO_WEAR_TAHT_HOMEPAGE_SECTION_BUTTON_ID_TEXT,
  HOW_TO_WEAR_TAHT_SECTION_BUTTON_ID_TEXT,
} from "./constants";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";

const ImageAtom = dynamic(() => import("../../Atoms/ImageAtom"));
const PrimaryButton = dynamic(() =>
  import("../../Atoms/Buttons/PrimaryButton")
);
const SectionHeading = dynamic(() =>
  import("../../Atoms/Typography/SectionHeading")
);
const WhoWeAreHeading = dynamic(() =>
  import("../../Atoms/Typography/WhoWeAreHeading")
);
const StylingSessionDescription = dynamic(() =>
  import("../../Atoms/Typography/StylingSessionDescription")
);
const PrimaryButtonECommerceOnly = dynamic(() =>
  import("../../Atoms/Buttons/PrimaryButtonECommerceOnly")
);

const HowToWearThatSection = ({ data }) => {
  const secondaryTitle = data?.primary?.secondary_title?.[0]?.text;
  const isBreakpoint = useMediaQuery(MOBILE_SCREEN);
  const router = useRouter();
  return (
    <div>
      <div className={HOW_TO_WEAR_TAHT_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        {secondaryTitle ? (
          <WhoWeAreHeading
            text={secondaryTitle}
            alignment={TEXT_ALIGNMENT_CLASS_WITH_PADDING_TOP}
          />
        ) : null}
        <StylingSessionDescription text={data?.primary?.description} />
      </div>

      <div className={HOW_TO_WEAR_TAHT_INNER_CONTAINER_TWO}>
        <Slider {...settings}>
          {data?.items?.map((item, index) => {
            return (
              <ImageAtom
                key={index}
                image={
                  isBreakpoint
                    ? item?.mobile_view_product_image
                    : item?.product_image
                }
                style={IMAGE_BACKGROUND_STYLE}
                alt="How To Wear That"
              />
            );
          })}
        </Slider>
      </div>
      {data?.variation === VERSION_2 ? (
        <PrimaryButtonECommerceOnly
          text={data?.primary?.button_text}
          href={`${process.env.OLD_WEB_URL}${ORDER_A_LOOK}`}
          buttonId={HOW_TO_WEAR_TAHT_SECTION_BUTTON_ID_TEXT}
        />
      ) : (
        <div className={HOW_IT_WORKS_SECOND_ROW}>
          <PrimaryButton
            text={getSectionButtonText(router?.locale)}
            href={SIGN_UP_ENDPOINT}
            section={SURVEY_START_SECTIONS.HowToWearThat}
            buttonId={HOW_TO_WEAR_TAHT_HOMEPAGE_SECTION_BUTTON_ID_TEXT}
          />
        </div>
      )}
    </div>
  );
};

export default HowToWearThatSection;
