import RoundLeftArrow from "../../Atoms/CarouselElements/RoundLeftArrow/RoundLeftArrow";
import RoundRightArrows from "../../Atoms/CarouselElements/RoundRightArrow/RoundRightArrow";

export const settings = {
  dots: false,
  slidesToShow: 4,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  nextArrow: <RoundRightArrows />,
  prevArrow: <RoundLeftArrow />,
  arrows: true,
  responsive: [
    {
      breakpoint: 1830,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 1370,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export const TESTIMONIAL_INNER_CONTAINER_ONE =
  "max-w-full md:max-w-[60%]  mx-2 md:mx-auto  md:mb-10";
export const TESTIMONIAL_INNER_CONTAINER_TWO =
  "max-w-full lg:max-w-[80%] mx-auto ";
