import { PrismicRichText } from "@prismicio/react";
import React, { useState } from "react";
import {
  API_BOOK_STYLING_SESSION,
  WIDTH_200_PX,
} from "../../../public/Constants/EnumConstants";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton/PrimaryButton";
import AppointmentBookedPopup from "../../Atoms/Popups/AppointmentBookedPopup/AppointmentBookedPopup";
import ErrorToast from "../../Atoms/Popups/ErrorToast/ErrorToast";
import { InputField } from "../../Atoms/Inputs/InputField";
import DatePickerField from "../../Atoms/Inputs/DatePickerField";
import TimePickerField from "../../Atoms/Inputs/TimePickerField/TimePickerField";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  VIP_TREATMENT_HEADING,
  BOOK_A_SESSION_TEXT,
  VIP_TREATMENT_DIV,
  FORMS_CONTAINER,
  BUTTON_CONTAINER,
  TEXT_TYPE,
  EMAIL_TYPE,
  DATE_TYPE,
  TIME_TYPE,
  BUTTON_TEXT,
  SUBMIT_TYPE,
  FORM_CONTAINER,
  EMAIL_FIELD,
  DATE_FIELD,
  NAME_FIELD,
  TIME_FIELD,
  INPUT_FIELD_CONTAINER,
  FORM_HEADING,
} from "./constants";

import { DEFAULT_VALUES, getFormSchema } from "./FormSchema";
import { useRouter } from "next/navigation";

const StylingSessionFormSection = ({ data }) => {
  const { locale } = useRouter();
  const schema = getFormSchema(locale);
  const [showModal, setShowModal] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [fromSubmissionInProgress, setFromSubmissionInProgress] =
    useState(false);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(schema),
  });
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const onsubmit = (e) => {
    setFromSubmissionInProgress(true);
    fetch(API_BOOK_STYLING_SESSION, {
      method: "POST",
      body: JSON.stringify(e),
    })
      .then((data) => {
        if (data.status === 200) {
          setShowModal(!showModal);
          setFromSubmissionInProgress(false);
          reset();
          return;
        }
        setShowErrorToast(true);
        setFromSubmissionInProgress(false);
        window.newrelic?.noticeError(
          `error in ${API_BOOK_STYLING_SESSION} api ${data.status}`,
          { attribute1: { data: JSON.stringify(e) } }
        );

        setTimeout(() => {
          setShowErrorToast(false);
        }, 4000);
        console.log(data);
      })
      .catch((error) => {
        window.newrelic?.noticeError(
          `error in ${API_BOOK_STYLING_SESSION} in .catch ${error}`
        );
        console.log(error);
        setFromSubmissionInProgress(false);
      });
  };
  return (
    <>
      <div>
        <div className={VIP_TREATMENT_DIV}>
          <div className={VIP_TREATMENT_HEADING}>
            <PrismicRichText
              field={data?.title}
              components={{
                heading1: ({ children }) => <p>{children}</p>,
              }}
            />
          </div>
          <div className={BOOK_A_SESSION_TEXT}>
            <PrismicRichText
              field={data?.description}
              components={{
                paragraph: ({ children }) => <p>{children}</p>,
              }}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(onsubmit)}>
          <div className={FORMS_CONTAINER}>
            <div className={FORM_CONTAINER}>
              <PrismicRichText
                field={data?.your_details}
                components={{
                  paragraph: ({ children }) => (
                    <p className={FORM_HEADING}>{children}</p>
                  ),
                }}
              />
              <div className={INPUT_FIELD_CONTAINER}>
                <InputField
                  label={data?.name?.[0]?.text}
                  type={TEXT_TYPE}
                  // placeholder={NAME_PLACEHOLDER}
                  field={NAME_FIELD}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className={INPUT_FIELD_CONTAINER}>
                <InputField
                  label={data?.email?.[0]?.text}
                  type={EMAIL_TYPE}
                  // placeholder={EMAIL_PLACEHOLDER}
                  field={EMAIL_FIELD}
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
            <div className={FORM_CONTAINER}>
              <PrismicRichText
                field={data?.appointment_details}
                components={{
                  paragraph: ({ children }) => (
                    <p className={FORM_HEADING}>{children}</p>
                  ),
                }}
              />
              <div className={INPUT_FIELD_CONTAINER}>
                <Controller
                  control={control}
                  name={DATE_FIELD}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <DatePickerField
                      value={value}
                      label={data?.date?.[0]?.text}
                      field={name}
                      errors={errors}
                      onChange={onChange}
                      onBlur={onBlur}
                      minDate={tomorrow}
                    />
                  )}
                />
              </div>
              <div className={INPUT_FIELD_CONTAINER}>
                <Controller
                  control={control}
                  name={TIME_FIELD}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, name } }) => (
                    <TimePickerField
                      value={value}
                      label={data?.time?.[0]?.text}
                      field={name}
                      errors={errors}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={BUTTON_CONTAINER}>
            <PrimaryButton
              text={data?.confirm_booking_button_text}
              type={SUBMIT_TYPE}
              processing={fromSubmissionInProgress}
              width={WIDTH_200_PX}
            />
          </div>
        </form>
      </div>
      {showModal ? (
        <AppointmentBookedPopup
          showModal={showModal}
          setShowModal={setShowModal}
          description={data?.popup_description}
          primaryButtonText={data?.popup_primary_button_text}
          tertiaryButtonText={data?.popup_tertiary_button_text}
        />
      ) : null}
      {showErrorToast ? <ErrorToast /> : null}
    </>
  );
};

export default StylingSessionFormSection;
