import React from "react";
import Slider from "react-slick";
import dynamic from "next/dynamic";
import { LATEST_DROP_TEXT, settings, TAMARA_SECTION_TITLE } from "./constants";
import {
  CUSTOM_PRIMARY_BUTTON_CLASS,
  IMAGE_CONTAINER_CLASS,
  TESTIMONIAL_BUTTON_CONTAINER,
  TESTIMONIAL_INNER_CONTAINER_ONE,
  TESTIMONIAL_INNER_CONTAINER_TWO,
} from "./Style";

import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { BANNER_BUTTON_ID_TEXT } from "../../Molecules/NewBannerSection/constants";
import { FRONTEND_ENDPOINTS } from "../../../public/Constants/endpoints";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";

const SectionHeading = dynamic(
  () => import("../../Atoms/Typography/SectionHeading")
);
const SessionDescription = dynamic(
  () => import("../../Atoms/Typography/SessionDescription")
);
const LatestDropsSection = ({ data }) => {
  const buttonHref =
    data?.primary?.title[0].text === TAMARA_SECTION_TITLE
      ? FRONTEND_ENDPOINTS?.TAMARA
      : SIGN_UP_ENDPOINT;

  return (
    <div>
      <div className={TESTIMONIAL_INNER_CONTAINER_ONE}>
        <SectionHeading text={data?.primary?.title} />
        <SessionDescription text={data?.primary?.description} />
      </div>
      <div className={TESTIMONIAL_INNER_CONTAINER_TWO}>
        <Slider {...settings}>
          {data?.items?.map((item, index) => {
            return (
              <img
                key={index}
                src={item?.image?.url}
                className={IMAGE_CONTAINER_CLASS}
                alt={LATEST_DROP_TEXT}
                id={LATEST_DROP_TEXT}
                loading="lazy"
              />
            );
          })}
        </Slider>
      </div>
      <div className={TESTIMONIAL_BUTTON_CONTAINER}>
        <NewPrimaryButtonWrapper
          text={data?.primary?.button_text}
          href={buttonHref}
          section={SURVEY_START_SECTIONS?.FullyStyleInclusive}
          buttonId={BANNER_BUTTON_ID_TEXT}
          className={CUSTOM_PRIMARY_BUTTON_CLASS}
        />
      </div>
    </div>
  );
};

export default LatestDropsSection;
