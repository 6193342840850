import React from "react";

import {
  BACKGROUND_SHADOW,
  MODAL_BODY,
  MODAL_BODY_TEXT_CLASS,
  MODAL_CONTAINER,
  MODAL_CONTENT_CONTAINER,
  MODAL_FOOTER,
  MODAL_HEADER,
  MODAL_SUB_CONTAINER,
  PRIMARY_BUTTON_CONTAINER,
  TERTIARY_BUTTON_CLASS,
} from "./constants";
import PrimaryButton from "../../Buttons/PrimaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { SuccessTick } from "../../../../public/Theme";
import { SIGN_UP_ENDPOINT } from "../../../../public/Constants/EnumConstants";
import { PrismicRichText } from "@prismicio/react";
import { useRouter } from "next/navigation";

const AppointmentBookedPopup = ({
  showModal,
  setShowModal,
  description,
  primaryButtonText,
  tertiaryButtonText,
}) => {
  const router = useRouter();
  const buttonText = primaryButtonText?.[0]?.text;
  return (
    <>
      <div
        onClick={() => {
          setShowModal(!showModal);
          router.push("/");
        }}
        className={MODAL_CONTAINER}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className={MODAL_SUB_CONTAINER}
        >
          <div className={MODAL_CONTENT_CONTAINER}>
            <div className={MODAL_HEADER}>
              <img src={SuccessTick} alt={"Tick Icon"} loading="lazy" />
            </div>

            <div className={MODAL_BODY}>
              <PrismicRichText
                field={description}
                components={{
                  heading: ({ children }) => (
                    <p className={MODAL_BODY_TEXT_CLASS}>{children}</p>
                  ),
                }}
              />
            </div>

            <div className={MODAL_FOOTER}>
              <div className={PRIMARY_BUTTON_CONTAINER}>
                <PrimaryButton
                  text={buttonText}
                  onClick={() => setShowModal(false)}
                  href={SIGN_UP_ENDPOINT}
                />
              </div>
              <TertiaryButton
                text={tertiaryButtonText}
                className={TERTIARY_BUTTON_CLASS}
                href={`/`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={BACKGROUND_SHADOW}></div>
    </>
  );
};

export default AppointmentBookedPopup;
