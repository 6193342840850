export const SECTION_CONTAINER =
  "bg-FORM_BG flex flex-col justify-items-center";
export const SECTION_SUB_CONTAINER = "w-[90%] md:w-auto  self-center";

export const FORM_CONTAINER = "flex w-auto self-center grid gap-x-0 md:gap-x-8";
export const FORM_HEADING =
  " font-medium text-xl md:text-2xl font-Montserrat text-center mb-[12px] md:mb-[20px] ";
export const FIELDS_CONTAINER = "grid grid-cols-1 md:grid-cols-2 gap-x-[4%] ";
export const FIELD_CONTAINER = "mt-[9px] mb-[9px]";
export const PAYMENT_METHOD_HEADING =
  "font-medium text-xl md:text-2xl font-Montserrat text-center mt-[25px] md:mt-[30px]";
export const CARD_INFO_CONTAINER =
  "grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-12 lg:gap-x-16 mb-[25px] md:mb-[35px] text-left";
export const CVV_EXPIRY_CONTAINER =
  "grid grid-cols-2 gap-x-4 gap-x-12 md:gap-x-10";
export const BUTTON_CONTAINER = "grid mb-[28px] md:mb-[46px]";
export const PAYMENT_METHOD_CONTAINER = "mt-[12px] md:mt-[20px]";
export const LABEL_CLASS = "mb-[12px] md:mb-[20px]";
export const RADIO_BUTTON = "mx-[10px] self-center accent-Primary_Default";

export const SENDER_FULL_NAME_FIELD = "sender_full_name";
export const SENDER_EMAIL_FIELD = "sender_email";
export const DATE_FIELD = "date";
export const TIME_FIELD = "time";
export const CARD_NUMBER_FIELD = "credit_card_number";
export const EXPIRY_FIELD = "expiry";
export const CVV_FIELD = "cvv";
export const CREDIT_CARD = "Credit Card";
export const APPLE_PAY = "Apple Pay";
