import React from "react";
import Accordion from "../../Atoms/Accordion";
import Playfair40pxSemibold from "../../Atoms/Typography/Playfair40pxSemibold";
import { FAQ_SLICE_CONTAINER, QUESTIONS_CONTAINER } from "./styles";
import { FAQ_ID } from "./constants";

const FaqSlice = ({ slice }) => {
  return (
    <div className={FAQ_SLICE_CONTAINER}>
      <Playfair40pxSemibold text={slice?.primary?.title} />
      <div className={QUESTIONS_CONTAINER}>
        {slice?.items?.map((item, index) => (
          <Accordion
            question={item?.question}
            answer={item?.answer}
            id={index + FAQ_ID}
            image={item?.answer_image}
            key={index + FAQ_ID}
          />
        ))}
      </div>
    </div>
  );
};

export default FaqSlice;
