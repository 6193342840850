export const CONTAINER_CLASS =
  "sm:flex justify-center max-w-[92%] sm:max-w-[95%] 2xl:max-w-[80%] mx-auto";

export const INNER_CONTAINER_ONE =
  "block  sm:flex w-full justify-center basis-full sm:basis-1/2 xl:basis-5/12";
export const TEXT_CONTAINER =
  "    flex justify-between flex-col gap-4 lg:gap-10";

export const TEXT_INNER_CONTAINER_ONE =
  "ml-[35%]  md:ml-[50%] lg:ml-[60%] mt-2 md:mt-7 2xl:mt-11 min-w-[180px] ";
export const TEXT_INNER_CONTAINER_TWO =
  "mx-auto mt-4 lg:mt-auto sm:mx-5 lg:mx-7 basis-full w-full sm:basis-1/2 xl:basis-7/12";
