"use client";
import React from "react";
import StylingSessionSection from "../../components/Molecules/StylingSessionSection";

const StylingSession = ({ slice }) => (
  <section>
    {/* <StylingSessionSection data={slice?.primary} /> */}

    <style jsx>{`
      section {
        margin: 6em auto;
        text-align: right;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: 3em auto;
        }
      }
    `}</style>
  </section>
);

export default StylingSession;
