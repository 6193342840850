import React from "react";

import {
  CARD_FLEX_CLASS,
  CARD_CONTAINER_CLASS,
  DESCRIPTION,
  HEADING_CONTAINER,
  HEADING_ICON,
  TOOL_TIP_COLOR,
  PERKS_ICON,
} from "./constants";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";
import MontserratBaseLight from "../../Atoms/Typography/MontserratBaseLight";
import MontserratBaseMedium from "../../Atoms/Typography/MontserratBaseMedium";
import Rectangle from "../../Atoms/HowItWorksVersion2Atoms/Rectangle";

const HowItWorksCardVersion2 = ({
  step,
  heading,
  description,
  image,
  alignment,
  index,
  arabic_order_class,
  last,
}) => {
  return (
    <div className={CARD_CONTAINER_CLASS + arabic_order_class} key={index}>
      <div className={CARD_FLEX_CLASS} key={index}>
        <div className={HEADING_CONTAINER}>
          <div className={heading?.[0]?.text ? HEADING_ICON : PERKS_ICON}>
            <ImageAtom image={image} style={" object-contain "} />
          </div>
          {heading?.[0]?.text ? (
            <MontserratBaseMedium text={heading} alignment={alignment} />
          ) : null}
        </div>
        <div className={DESCRIPTION}>
          <MontserratBaseLight simpleText={description} alignment={alignment} />
        </div>
      </div>
      {!last ? <Rectangle fillColor={TOOL_TIP_COLOR} /> : null}
    </div>
  );
};

export default HowItWorksCardVersion2;
