export const ACCORDION_DETAILS =
  "transition-[height] ease-in-out pb-2 delay-100 duration-300 mt-2 border-b-2  border-White_Lines open:border-0 duration-2000 text-left";
export const ACCORDION_SUMMARY =
  "transition-opacity delay-100 duration-300 translate-y-0  cursor-pointer flex flex-row justify-between items-center";
export const ACCORDION_EXPANDED_DATA =
  "bg-white px-2 hidden !text-left transition-all duration-300 ease-in-out z-10";
export const ACCORDION_OPEN_TRANSITION =
  " h-full !block translate-y-1 opacity-100 duration-500";
export const ACCORDION_CLOSE_TRANSITION =
  " h-0  pt-2 translate-y-0 opacity-0 duration-500";
export const IMAGE_CONTAINER = "my-2";
export const ARROW_CONTAINER = "";
export const ARROW_CLASS = "transition-all delay-50 duration-300 ";
export const ARROW_ROTATE = " rotate-180 ";
export const QUESTION_TEXT_CONTAINER = '';

export const HandleToggle = (id, accordionOpen, setAccordionOpen) => {
  setAccordionOpen(accordionOpen !== id && id);
};
