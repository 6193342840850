"use client";
import React from "react";
import StylingSessionBannerSection from "../../components/Molecules/StylingSessionBannerSection";

const StylingSessionBanner = ({ slice }) => (
  <section>
    <StylingSessionBannerSection data={slice?.primary} />
    <style jsx>{`
      section {
        margin: auto;
        max-width: 1440px;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: auto;
        }
      }
    `}</style>
  </section>
);

export default StylingSessionBanner;
