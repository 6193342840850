export const PAGE_CONTAINER = "flex flex-col justify-items-center ";
export const SECTION_SUB_CONTAINER = "w-[80%] self-center";
export const PAGE_HEADING =
  "text-Montserrat text-2xl md:text-[40px] font-semibold text-center leading-9 md:leading-[72px]";
export const CHOOSE_GIFT_CARD_HEADING =
  "text-center md:text-left text-Montserrat font-normal text-sm md:text-2xl leading-[16.8px] md:leading-9";
export const FORM_AND_CARD_DESIGN_CONTAINER =
  "flex flex-col lg:flex-row  lg:gap-x-6 mb-[35px] md:mb-[80px]";
export const IMAGE_SELECTOR_CONTAINER = "lg:w-[80%] ";
export const SENDER_DETAILS_FORM_CONTAINER =
  "md:w-[70%] mx-auto mb-[25px] md:mb-[35px]";
export const BUTTON_CONTAINER = "grid mb-[28px] md:mb-[46px]";
export const HEADING_CLASS =
  "text-center md:text-start text-[14px] md:text-[24px] mt-[35px] md:mt-[50px] mb-[12px] md:mb-[20px]";

export const SUBMIT_TYPE = "submit";
