import React from "react";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import MontserratXLNormal from "../../Atoms/Typography/MontserratXLNormal";
import BlogBodyImage from "../BlogBodyImage";
import { BLOG_BODY_IMAGE_CONTAINER, BLOG_IMAGES_CONTAINER } from "./constants";

const BlogBodyV2 = ({ data }) => {
  return (
    <div>
      <MontserratXLNormal text={data?.description_part_1} />
      <div className={BLOG_IMAGES_CONTAINER}>
        <div className={BLOG_BODY_IMAGE_CONTAINER}>
          <BlogBodyImage
            image={data?.image_1}
            imageDescription={data?.image_1_description}
            pinterestLink={data?.pinterest_link_1}
          />
        </div>
        <div className={BLOG_BODY_IMAGE_CONTAINER}>
          <BlogBodyImage
            image={data?.image_2}
            imageDescription={data?.image_2_description}
            pinterestLink={data?.pinterest_link_2}
          />
        </div>
      </div>
      <MontserratXLNormal text={data?.description_part_2} />
      <div className={BLOG_IMAGES_CONTAINER}>
        <div className={BLOG_BODY_IMAGE_CONTAINER}>
          <BlogBodyImage
            image={data?.image_3}
            imageDescription={data?.image_3_description}
            pinterestLink={data?.pinterest_link_3}
          />
        </div>
      </div>
      <MontserratXLNormal text={data?.description_part_3} />
      <div>
        {data?.button_text ? (
          <PrimaryButton
            text={data?.button_text}
            href={data?.button_link}
            buttonId={data?.button_id}
          />
        ) : null}
      </div>
    </div>
  );
};

export default BlogBodyV2;
