export const HOW_WE_WORK_MAIN_CONTAINER =
  "relative w-fit mt-[80px] md:mt-[120px] m-3 px-4 py-5 lg:py-12 border-New_Primary_Default  ";
export const HOW_WE_WORK_STYLE_CLASS =
  "absolute w-[305px] sm:w-[380px] lg:w-[457px] right-1/2 transform translate-x-1/2 -translate-y-[35px] lg:-translate-y-[65px] bg-white flex justify-center";
export const HOW_WE_WORK_TITLE =
  "font-Montserrat font-semibold text-[22px] xs:text-2xl sm:text-[29px] lg:text-[32px]";
export const HOW_IT_WORKS_FIRST_ROW =
  " flex flex-col lg:flex-row  gap-4 lg:gap-10 mt-3 ";
export const HOW_IT_WORKS_SECOND_ROW =
  " absolute w-max right-1/2 transform translate-x-1/2 lg:translate-y-[25px] bg-white flex justify-center ";
const BUTTON_TEXT = "Start The Style Quiz";
const BUTTON_TEXT_AR = "ابدأ اختبار الأسلوب";

export const HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT =
  "how-it-works-section-button";
