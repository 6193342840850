import Image from "next/image";
import React from "react";
import { NewLeftArrow } from "../../../../public/Theme";

const NewCenterAlignedRoundLeftArrow = ({ className, style, onClick }) => {
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <Image alt="left arrow" fill sizes="100vw" src={NewLeftArrow} />
    </button>
  );
};

export default NewCenterAlignedRoundLeftArrow;
