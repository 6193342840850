"use client";
import React, { useState } from "react";
import ImageAtom from "../ImageAtom/ImageAtom";
import { DownArrow } from "../../../public/Theme";
import TypographyMontserrat from "../TypographyMontserrat/";
import {
  ACCORDION_DETAILS,
  ACCORDION_EXPANDED_DATA,
  ACCORDION_SUMMARY,
  IMAGE_CONTAINER,
  ARROW_CONTAINER,
  QUESTION_TEXT_CONTAINER,
  ARROW_CLASS,
  ARROW_ROTATE,
  ACCORDION_CLOSE_TRANSITION,
  ACCORDION_OPEN_TRANSITION,
  HandleToggle,
} from "./constants";

const Accordion = ({ question, answer, id, image }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);
  return (
    <div
      className={
        accordionOpen === id
          ? ACCORDION_DETAILS + " !border-Tooltip"
          : ACCORDION_DETAILS
      }
      onClick={() => {
        HandleToggle(id, accordionOpen, setAccordionOpen);
      }}
    >
      <div className={ACCORDION_SUMMARY}>
        <div className={QUESTION_TEXT_CONTAINER}>
          <TypographyMontserrat
            align={"left"}
            children={question}
            fontWeight={"medium"}
            size={"xl"}
          />
        </div>
        <div className={ARROW_CONTAINER}>
          <img
            className={
              ARROW_CLASS + (accordionOpen === id ? ARROW_ROTATE : null)
            }
            src={DownArrow}
          />
        </div>
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        className={
          ACCORDION_EXPANDED_DATA +
          (accordionOpen === id
            ? ACCORDION_OPEN_TRANSITION
            : ACCORDION_CLOSE_TRANSITION)
        }
      >
        <TypographyMontserrat
          align={"left"}
          children={answer}
          fontWeight={"light"}
          size={"base"}
        />
        <div className={IMAGE_CONTAINER}>
          {image && accordionOpen ? <ImageAtom image={image} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
