import React from "react";
import StylistBio from "../../components/Molecules/StylistBio";

/**
 * @typedef {import("@prismicio/client").Content.StylistDataSectionSlice} StylistDataSectionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<StylistDataSectionSlice>} StylistDataSectionProps
 * @param { StylistDataSectionProps }
 */
const StylistDataSection = ({ slice }) => (
  <section>
    <StylistBio data={slice} />
    <style jsx>{`
      section {
        max-width: 1440px;
        margin: 2em auto;
        text-align: center;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: 2em auto;
        }
      }
    `}</style>
  </section>
);

export default StylistDataSection;
