export const MAIN_WRAPPER_CONTAINER =
  "flex flex-1 flex-col-reverse md:flex-row ";
export const CONTENT_CONTAINER =
  "grow md:basis-3/5 bg-Card_Background grid px-3 md:px-7 py-3 md:py-[30px] ";
export const CONTENT_SUB_CONTAINER =
  "flex flex-col self-end gap-y-3 md:gap-y-5 mt-9 md:mt-[120px] ";
export const HEADING_CLASS = " leading-[33px] lg:leading-[48px]	";
export const CTA_BUTTON_CLASS = "!text-New_Primary_Default";
export const IMAGE_CONTAINER = " md:basis-2/5  ";
export const IMAGE_CLASS = "object-cover h-full  w-full";
