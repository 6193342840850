export const settings = {
  variableWidth: false,
  dots: false,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 500,
  slidesToShow: 6,
  cssEase: "linear",
  easing: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },

    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};
