"use client";
import React, { useRef, useState } from "react";
import VolumeIcon from "../../Atoms/VideoElements/VolumeIcon";
import PlayingIcon from "../../Atoms/VideoElements/PlayingIcon";
import {
  VIDEO_OVERLAY,
  CONTROLS_CONTAINER,
  PLAY_BUTTON_CONTAINER,
  VIDEO_CLASS,
  MUTE_BUTTON_CONTAINER,
  VIDEO_CONTAINER,
} from "./styles";

const NewCustomVideoPlayer = ({
  autoplay,
  className,
  controls,
  muted,
  videoContainerClass,
  videoPlaceHolder,
  videoUrl,
}) => {
  const videoRef = useRef(null);
  const [showButtons, setShowButtons] = useState({
    play: !autoplay ? true : false,
    muted: muted,
  });

  const handlePlayPause = () => {
    if (videoRef?.current?.paused) {
      videoRef?.current?.play();
      videoRef?.current?.classList?.remove(VIDEO_OVERLAY);
      setShowButtons({ ...showButtons, play: false });
    } else {
      videoRef?.current?.pause();
      videoRef?.current?.classList?.add(VIDEO_OVERLAY);
      setShowButtons({ ...showButtons, play: true });
    }
  };
  return (
    <div className={videoContainerClass || VIDEO_CONTAINER}>
      <video
        ref={videoRef}
        playsInline={true}
        webkit-playsinline={"true"}
        disablePictureInPicture={true}
        loop={true}
        className={className || VIDEO_CLASS}
        preload="none"
        muted={showButtons?.muted}
        autoPlay={autoplay}
        controls={controls}
        poster={videoPlaceHolder}
        src={videoUrl}
      ></video>
      <div
        className={CONTROLS_CONTAINER}
        onClick={() => {
          handlePlayPause();
        }}
      >
        <div className={PLAY_BUTTON_CONTAINER}></div>

        <div />
      </div>
      <div
        tabIndex={0}
        className={MUTE_BUTTON_CONTAINER + " flex gap-3 top-6 right-3"}
      >
        <div
          onClick={() =>
            setShowButtons({ ...showButtons, muted: !showButtons?.muted })
          }
        >
          <VolumeIcon showButtons={showButtons} />
        </div>
        <div onClick={() => handlePlayPause()}>
          <PlayingIcon showButtons={showButtons} />
        </div>
      </div>
    </div>
  );
};

export default NewCustomVideoPlayer;
