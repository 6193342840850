import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Montserrat_40PX_SEMIBOLD } from "./constants";

const Montserrat40pxSemibold = ({
  text,
  simpleText,
  alignment,
  customStyle,
}) => {
  const textComponent = !simpleText ? (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={Montserrat_40PX_SEMIBOLD + customStyle}>{children}</p>
        ),
        paragraph: ({ children }) => (
          <p className={Montserrat_40PX_SEMIBOLD + customStyle}>{children}</p>
        ),
      }}
    ></PrismicRichText>
  ) : (
    <p className={Montserrat_40PX_SEMIBOLD + customStyle}>{text}</p>
  );
  return textComponent;
};

export default Montserrat40pxSemibold;
