"use client";
import React from "react";
import AmbassadorRegistrationForm from "../../components/Molecules/AmbassadorRegistrationForm";

const AmbassadorProgramForm = ({ slice }) => (
  <section>
    <AmbassadorRegistrationForm data={slice?.primary} items={slice?.items} />
    <style jsx>{`
      section {
        margin: auto;
      }
    `}</style>
  </section>
);

export default AmbassadorProgramForm;
