import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import {
  BANNER_CLASS,
  BANNER_DESCRIPTION,
  BANNER_HEADING,
  DESCRIPTION_CONTAINER,
  OVER_LAY_HEADING,
  BUTTON_CONTAINER,
  BREAK_POINT_WIDTH,
  FORM_LINK,
  BANNER_CLASS_V2,
} from "./constants";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import ImageAtom from "../../Atoms/ImageAtom/ImageAtom";
import SectionHeading from "../../Atoms/Typography/SectionHeading";

const AmbassadorBannerSection = ({ data }) => {
  const isBreakpoint = useMediaQuery(BREAK_POINT_WIDTH);
  return (
    <div>
      <div className={BANNER_CLASS_V2}>
        <SectionHeading text={data?.title} />
      </div>
      <ImageAtom
        image={
          isBreakpoint
            ? data?.banner_collage_image_mobile
            : data?.banner_collage_image
        }
      />
      <div className={DESCRIPTION_CONTAINER}>
        <PrismicRichText
          field={data?.description}
          components={{
            paragraph: ({ children }) => (
              <p className={BANNER_DESCRIPTION}>{children}</p>
            ),
          }}
        />
      </div>
      <div className={BUTTON_CONTAINER}>
        <PrimaryButton text={data?.apply_button?.[0]?.text} href={FORM_LINK} />
      </div>
    </div>
  );
};

export default AmbassadorBannerSection;
