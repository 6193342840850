import React from "react";
import { playIcon, pauseIcon } from "../../../../public/Theme";

const PlayingIcon = ({ showButtons }) => {
  return (
    <>
      {showButtons?.play ? (
        <img src={playIcon} className="w-8 h-8 lg:w-10 lg:h-10" />
      ) : (
        <img src={pauseIcon} className="w-8 h-8 lg:w-10 lg:h-10" />
      )}
    </>
  );
};

export default PlayingIcon;
