"use client";
import React from "react";
import HeadingWithDescription from "../../components/Molecules/HeadingWithDescription";

/**
 * @typedef {import("@prismicio/client").Content.AboutUsTextSectionSlice} AboutUsTextSectionSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<AboutUsTextSectionSlice>} AboutUsTextSectionProps
 * @param { AboutUsTextSectionProps }
 */
const AboutUsTextSection = ({ slice }) => (
  <section>
    <HeadingWithDescription data={slice?.primary} />
    <style jsx>{`
      section {
        margin: 4em auto;
        text-align: center;
      }
    `}</style>
  </section>
);

export default AboutUsTextSection;
