import React from "react";
import { PROCESSING_GIF_ALT } from "./constants";
import { PRIMARY_BUTTON_CLASS, PRIMARY_BUTTON_DISABLED_CLASS } from "./styles";
import { LoadingWhiteSpinner } from "../../../../public/Theme";
import Image from "next/image";
import Link from "next/link";
import { SIGN_UP_ENDPOINT } from "../../../../public/Constants/EnumConstants";

const PrimaryButton = ({
  text,
  onClick,
  processing,
  width,
  href,
  className,
  style,
  type,
  disabled,
  section,
  buttonId,
}) => {
  const buttonClass = disabled
    ? PRIMARY_BUTTON_DISABLED_CLASS
    : PRIMARY_BUTTON_CLASS;
  return href ? (
    <Link
      href={href}
      id={buttonId}
      data-cy={buttonId}
      passHref
      className={width + PRIMARY_BUTTON_CLASS + className}
      style={style}
      onClick={() => {
        if (href?.includes(SIGN_UP_ENDPOINT) && section) {
          gtag("event", "survey_start_click", {
            section: section,
            button_text: text,
            content_id: "StartSurveyClick",
          });
        }
        if (onClick) {
          onClick();
        }
      }}
      name={text}
    >
      {processing ? (
        <Image
          src={LoadingWhiteSpinner}
          fill
          alt={PROCESSING_GIF_ALT}
          sizes="100vw"
        />
      ) : (
        text
      )}
    </Link>
  ) : (
    <button
      type={type}
      className={width + buttonClass + className}
      style={style}
      onClick={onClick}
      disabled={disabled}
      id={buttonId}
      data-cy={buttonId}
    >
      {processing ? (
        <img
          src={LoadingWhiteSpinner}
          alt={PROCESSING_GIF_ALT}
          className="w-7 h-7 mx-3"
        />
      ) : (
        text
      )}
    </button>
  );
};

export default PrimaryButton;
