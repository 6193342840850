import React from "react";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import MontserratXLNormal from "../../Atoms/Typography/MontserratXLNormal";
import BlogBodyImage from "../BlogBodyImage";
import CustomVideoPlayer from "../CustomVideoPlayer/";
import { BLOG_BODY_IMAGE_CONTAINER, BLOG_IMAGES_CONTAINER } from "./constants";

const BlogBodyWithVideo = ({ data, items }) => {
  return (
    <div>
      <MontserratXLNormal text={data?.description} />
      {items?.map((item, index) => {
        return (
          <div key={index}>
            <MontserratXLNormal text={item?.description} />
            <div>
              {item?.video?.url ? (
                <CustomVideoPlayer autoplay muted videoUrl={item?.video?.url} />
              ) : null}
            </div>
            <div className={BLOG_IMAGES_CONTAINER}>
              {item?.image_1?.dimensions ? (
                <div className={BLOG_BODY_IMAGE_CONTAINER}>
                  <BlogBodyImage
                    image={item?.image_1}
                    imageDescription={item?.image_1_description}
                    pinterestLink={item?.pinterest_link_1}
                  />
                </div>
              ) : null}
              {item?.image_2?.dimensions ? (
                <div className={BLOG_BODY_IMAGE_CONTAINER}>
                  <BlogBodyImage
                    image={item?.image_2}
                    imageDescription={item?.image_2_description}
                    pinterestLink={item?.pinterest_link_2}
                  />
                </div>
              ) : null}
              {item?.image_3?.dimensions ? (
                <div className={BLOG_BODY_IMAGE_CONTAINER}>
                  <BlogBodyImage
                    image={item?.image_3}
                    imageDescription={item?.image_3_description}
                    pinterestLink={item?.pinterest_link_3}
                  />
                </div>
              ) : null}
            </div>
            <div>
              {item?.button_text ? (
                <PrimaryButton
                  text={item?.button_text}
                  href={item?.button_link}
                  buttonId={item?.button_id}
                />
              ) : null}
              <br />
              <br />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlogBodyWithVideo;
