import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { Montserrat_BASE_MEDIUM } from "./constants";

const MontserratBaseMedium = ({ text, simpleText, customStyle, alignment }) => {
  const textComponent = !simpleText ? (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={Montserrat_BASE_MEDIUM + customStyle}>{children}</p>
        ),
        paragraph: ({ children }) => (
          <p className={Montserrat_BASE_MEDIUM + customStyle}>{children}</p>
        ),
      }}
    ></PrismicRichText>
  ) : (
    <p className={Montserrat_BASE_MEDIUM + customStyle}>{simpleText}</p>
  );
  return textComponent;
};

export default MontserratBaseMedium;
