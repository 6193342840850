"use client";
import React from "react";
import { PrismicRichText } from "@prismicio/react";
import AmbassadorPerksAndBenefitsSection from "../../components/Molecules/AmbassadorPerksAndBenefitsSection";

const AmbassadorPerksAndBenefits = ({ slice }) => (
  <section>
    <AmbassadorPerksAndBenefitsSection
      data={slice?.primary}
      items={slice?.items}
    />

    <style jsx>{`
      section {
        margin: auto;
        max-width: 900px;
      }
    `}</style>
  </section>
);

export default AmbassadorPerksAndBenefits;
