"use client";
import React from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import Slider from "react-slick";
import {
  FLAT_LAYS_CONTAINER,
  WRAPPER_CONTAINER,
  INNER_CONTAINER,
  MEDIA_CLASS,
} from "./styles";
import { FLATLAYS_CAROUSEL_SETTINGS } from "./constants";
import SerenaVideoPlayer from "../CustomVideoPlayer/SerenaVideoPlayer";

const SerenaProductFlatlays = ({ data, items }) => {
  return (
    <div className={FLAT_LAYS_CONTAINER}>
      <TypographyMontserrat
        fontWeight={"normal"}
        children={data?.title}
        size={"14px"}
        color={"New_Primary_Default"}
      />
      <div className={WRAPPER_CONTAINER}>
        <div className={INNER_CONTAINER}>
          <Slider {...FLATLAYS_CAROUSEL_SETTINGS}>
            {items?.map((item, index) => {
              return (
                <SerenaVideoPlayer
                  key={index}
                  videoUrl={item?.media?.url}
                  muted
                  videoPlaceHolder={item?.video_placeholder?.url}
                  className={MEDIA_CLASS}
                  videoContainerClass={MEDIA_CLASS + " relative"}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SerenaProductFlatlays;
