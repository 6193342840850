import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { PLAYFAIR_4XL_SEMIBOLD } from "./constants";

const Playfair4xlSemibold = ({ text, simpleText, alignment, customStyle }) => {
  const textComponent = !simpleText ? (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <p className={customStyle + PLAYFAIR_4XL_SEMIBOLD}>{children}</p>
        ),
        paragraph: ({ children }) => (
          <p className={customStyle + PLAYFAIR_4XL_SEMIBOLD}>{children}</p>
        ),
      }}
    ></PrismicRichText>
  ) : (
    <p className={customStyle + PLAYFAIR_4XL_SEMIBOLD}>{simpleText}</p>
  );
  return textComponent;
};

export default Playfair4xlSemibold;
