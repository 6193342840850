import React from "react";
import dynamic from "next/dynamic";

import {
  HOW_IT_WORKS_FIRST_ROW,
  HOW_IT_WORKS_SECOND_ROW,
  HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT,
  HOW_WE_WORK_MAIN_CONTAINER,
  HOW_WE_WORK_STYLE_CLASS,
  HOW_WE_WORK_TITLE,
} from "./constants";
import {
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
} from "../../../public/Constants/EnumConstants";
import { PrismicRichText } from "@prismicio/react";

const StylistAboutMeCard = dynamic(() =>
  import("../../Molecules/StylistAboutMeCard")
);

import { useMediaQuery } from "../../CustomHooks/UseMediaQuery";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";

const StylistLittleAboutMeSection = ({ slice }) => {
  const breakpoint = useMediaQuery("770");
  const stylistCard = breakpoint
    ? slice?.primary?.stylist_card_mobile?.url
    : slice?.primary?.stylist_card?.url;

  return (
    <div className=" lg:px-20 mt-[-60px] sm:mt-[-90px] ">
      <div className=" flex flex-col lg:flex-row-reverse  items-center">
        <div className=" flex-1 flex justify-center px-5 ">
          <img
            src={slice?.primary?.stylist_image?.url}
            alt={slice?.primary?.stylist_image?.alt}
            className={" mb-8 rotate-[-2.79deg] xl:max-w-[595px] "}
          />
        </div>
        <div className=" flex-1 ">
          <div
            className={
              " relative w-fit m-[17px] px-[30px] py-7 border border-New_Primary_Default  "
            }
          >
            <div
              className={
                "absolute w-[245px] xs:w-[280px] sm:w-[380px] lg:w-[330px] xl:w-[457px] right-1/2 transform translate-x-1/2 -translate-y-[43px] bg-white flex justify-center"
              }
            >
              <PrismicRichText
                field={slice?.primary?.title}
                components={{
                  heading1: ({ children }) => (
                    <p
                      className={
                        "font-Montserrat font-semibold text-[22px] xs:text-2xl sm:text-[29px] xl:text-[32px]"
                      }
                    >
                      {children}
                    </p>
                  ),
                }}
              ></PrismicRichText>
            </div>
            <div className={" flex flex-col gap-4 mt-3"}>
              {slice?.items?.map((item, index) => (
                <StylistAboutMeCard
                  key={index}
                  index={index}
                  step={item?.index}
                  heading={item?.title}
                  description={item?.description}
                  image={item?.image}
                  descriptionStyle={" !leading-6 mt-2 "}
                />
              ))}
            </div>
            <div
              className={
                " absolute w-max right-1/2 transform translate-x-1/2 translate-y-[5px] bg-white flex justify-center "
              }
            >
              <NewPrimaryButtonWrapper
                text={slice?.primary?.button_text}
                href={SIGN_UP_ENDPOINT}
                section={SURVEY_START_SECTIONS?.YourStyleYourWay}
                buttonId={"little-about-me-button"}
              />
            </div>
          </div>
        </div>
      </div>
      <img
        src={stylistCard}
        alt={slice?.primary?.stylist_card_mobile?.alt}
        className={" mt-5 "}
      />
    </div>
  );
};

export default StylistLittleAboutMeSection;
