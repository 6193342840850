"use client";
import React, { useState } from "react";
import {
  INPUT_FIELD_CLASS,
  INPUT_FIELD_ERROR,
} from "../../../../public/Constants/EnumConstants";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import {
  INPUT_CONTAINER,
  LABEL,
  UNDER_THE_FIELD,
  CHARACTER_COUNTER,
  TEXT_AREA_CUSTOM,
} from "./constants";
import ErrorTextWithIcon from "../../Typography/ErrorTextWithIcon/ErrorTextWithIcon";

const TextArea = ({
  countCharacters,
  errors,
  label,
  type,
  placeholder,
  field,
  register,
  name,
  maxCharacterLength,
  customStyle,
}) => {
  const [characterCount, setCharacterCount] = useState(0);
  const inputClassName = errors?.[field]
    ? INPUT_FIELD_ERROR
    : INPUT_FIELD_CLASS;
  const errorMessage = name
    ? errors?.[name]?.message
    : errors?.[field]?.message;
  return (
    <div className={INPUT_CONTAINER}>
      <p className={LABEL}>{label}</p>
      <div>
        <textarea
          className={inputClassName + TEXT_AREA_CUSTOM + customStyle}
          type={type}
          placeholder={placeholder}
          maxLength={maxCharacterLength || null}
          {...register(field)}
          onChange={({ target }) => {
            const { value } = target;
            setCharacterCount(value?.length);
          }}
        />
        <div className={UNDER_THE_FIELD}>
          <ErrorTextWithIcon text={errorMessage} />
          {countCharacters && (
            <p className={CHARACTER_COUNTER}>
              {characterCount}/{maxCharacterLength}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TextArea;
