import React from "react";
import ThisOrThatSection from "../../components/Organisms/ThisOrThatSection";

const ThisOrThat = ({ slice }) => (
  <section>
    <ThisOrThatSection data={slice} />
    <style jsx>{`
      section {
        margin: 6em auto;
        text-align: center;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: 5em auto;
        }
      }
    `}</style>
  </section>
);

export default ThisOrThat;
