import React from "react";
import dynamic from "next/dynamic";

import {
  CONTAINER_CLASS,
  INNER_CONTAINER_ONE,
  TEXT_CONTAINER,
  TEXT_INNER_CONTAINER_ONE,
  TEXT_INNER_CONTAINER_TWO,
} from "./constants";
import TypographyPlayfair from "../../Atoms/TypographyPlayfair";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";

const ImageAtom = dynamic(() => import("../../Atoms/ImageAtom"));

const WhoWeAreSectionVersion2 = ({ data }) => {
  return (
    <div className={CONTAINER_CLASS} dir="ltr">
      <div className={INNER_CONTAINER_ONE}>
        <ImageAtom image={data?.banner_image} />
      </div>
      <div className={TEXT_INNER_CONTAINER_TWO}>
        <div className={TEXT_CONTAINER}>
          <TypographyPlayfair
            text={data?.title?.[0]?.text}
            align="left"
            size="6xl"
            fontWeight={"bold"}
          />
          <TypographyMontserrat
            text={data?.description?.[0]?.text}
            align="left"
            size="xl"
          />
          <TypographyMontserrat
            text={data?.secondary_title}
            align="left"
            size="xl"
            fontWeight={"bold"}
          />
          <div className={TEXT_INNER_CONTAINER_ONE}>
            <ImageAtom image={data?.signature_image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAreSectionVersion2;
