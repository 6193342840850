import Link from "next/link";
import React from "react";
import { PinterestIcon } from "../../../public/Theme";
import ImageAtom from "../../Atoms/ImageAtom";
import MontserratXsNormal from "../../Atoms/Typography/MontserratXsNormal";
import {
  IMAGE_CONTAINER,
  IMAGE_DESCRIPTION_CONTAINER,
  PINTEREST_LOGO_CLASS,
  PINTEREST_LOGO_CONTAINER,
} from "./constants";

const BlogBodyImage = ({ image, imageDescription, pinterestLink }) => {
  const pinterestLogo = pinterestLink ? (
    <Link href={pinterestLink}>
      <img src={PinterestIcon} className={PINTEREST_LOGO_CLASS} />
    </Link>
  ) : null;
  return (
    <div className={IMAGE_CONTAINER}>
      <ImageAtom image={image} />
      <div className={IMAGE_DESCRIPTION_CONTAINER}>
        <MontserratXsNormal simpleText={imageDescription} />
      </div>
      <div className={PINTEREST_LOGO_CONTAINER}>{pinterestLogo}</div>
    </div>
  );
};

export default BlogBodyImage;
