import Image from "next/image";
import React from "react";
import { LeftArrow } from "../../../../public/Theme";

const RoundLeftArrow = ({ className, style, onClick }) => {
  return (
    <button
      className={className}
      style={{ ...style, top: "30% !important" }}
      onClick={onClick}
    >
      <Image fill src={LeftArrow} alt="left arrow rounded" sizes="100vw" />
    </button>
  );
};

export default RoundLeftArrow;
