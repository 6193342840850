import React from "react";
import PerksAndBenefitsAtom from "../../Atoms/PerksAndBenefitsAtom";
import SectionHeading from "../../Atoms/Typography/SectionHeading";

import {
  CONTAINER,
  INNER_CONTAINER,
  BENEFIT_ICONS_CONTAINER,
} from "./constants";

const AmbassadorPerksAndBenefitsSection = ({ data, items }) => {
  return (
    <div className={CONTAINER}>
      <div className={INNER_CONTAINER}>
        <SectionHeading text={data?.title} />
      </div>
      <div className={BENEFIT_ICONS_CONTAINER}>
        {items?.map((item, index) => (
          <PerksAndBenefitsAtom
            detail={item?.benefit_icon_details}
            imgUrl={item?.benefit_icon?.url}
            bgColor={item?.icon_background_color?.[0]?.text}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default AmbassadorPerksAndBenefitsSection;
