import React, { useState } from "react";
import TypographyMontserrat from "../../Atoms/TypographyMontserrat";
import { VariantOption } from ".";
import {
  OPTIONS_CONTAINER,
  SECTION_CONTAINER,
  SUB_CONTAINER,
  VARIANTS_CONTAINER,
} from "./styles";
import { COLOR_TEXT } from "./constants";
import SerenaCarousalWithVideo from "../SerenaCarousalWithVideo";

const ProductVariantsSection = (props) => {
  const { data, items, variant, setVariant } = props;
  const [variantImages, setVariantImages] = useState(
    items?.filter((item) => item?.color_title === variant?.variant)
  );
  const setState = (selectedVariant) => {
    const images = items?.filter(
      (item) => item?.color_title === selectedVariant?.color_title
    );
    setVariant((prev) => ({
      ...prev,
      variant: selectedVariant?.color_title,
    }));
    setVariantImages(images);
    window && window?.scrollTo(0, 0);
  };

  return (
    <div className={SECTION_CONTAINER}>
      <SerenaCarousalWithVideo data={variantImages?.[0]} />

      <div className={SUB_CONTAINER}>
        <TypographyMontserrat
          children={data?.title}
          fontWeight={"normal"}
          size={"14px"}
          customStyle={" uppercase "}
        />
        <div className={VARIANTS_CONTAINER}>
          <TypographyMontserrat
            text={COLOR_TEXT}
            fontWeight={"normal"}
            size={"12px"}
          />
          <div className={OPTIONS_CONTAINER}>
            {items?.map((item, index) => {
              return (
                <VariantOption
                  item={item}
                  setState={setState}
                  variant={variant}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductVariantsSection;
