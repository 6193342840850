"use client";
import React from "react";
import AboutUsBannerSection from "../../components/Molecules/AboutUsBannerSection";

/**
 * @typedef {import("@prismicio/client").Content.AboutUsBannerSlice} AboutUsBannerSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<AboutUsBannerSlice>} AboutUsBannerProps
 * @param { AboutUsBannerProps }
 */
const AboutUsBanner = ({ slice }) => (
  <section>
    <AboutUsBannerSection data={slice} />
    <style jsx>{`
      section {
        margin: auto;
        text-align: center;
      }
    `}</style>
  </section>
);

export default AboutUsBanner;
