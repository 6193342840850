import React from "react";

import {
  CONTAINER_CLASS,
  INNER_CONTAINER,
  QOUTES_CLASS,
  VALUES_CONATINER,
} from "./constants";
import { ARABIC } from "../../../public/Constants/EnumConstants";
import { useRouter } from "next/navigation";
import SectionHeading from "../../Atoms/Typography/SectionHeading";
import {
  ArabicEnd,
  ArabicStart,
  LeftQoutes,
  RightQoutes,
} from "../../../public/Theme";
import Playfair4xlRegular from "../../Atoms/Typography/Playfair4xlRegular";

const StyleAdviceSection = ({ data }) => {
  const { locale } = useRouter();

  return (
    <div>
      <SectionHeading text={data?.primary?.title} />
      <div className={CONTAINER_CLASS}>
        <img
          src={locale === ARABIC ? ArabicStart : LeftQoutes}
          className={QOUTES_CLASS}
          alt={"Qoutes Icon"}
          loading="lazy"
        />
        <Playfair4xlRegular text={data?.primary?.description} />
        <div className={INNER_CONTAINER}>
          <img
            src={locale === ARABIC ? ArabicEnd : RightQoutes}
            className={QOUTES_CLASS}
            alt={"Qoutes Icon"}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default StyleAdviceSection;
