import React from "react";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton";
import MontserratXLNormal from "../../Atoms/Typography/MontserratXLNormal";
import BlogBodyImage from "../../Molecules/BlogBodyImage";
import {
  BLOG_BODY_IMAGE_CONTAINER,
  BLOG_IMAGES_CONTAINER,
  REPEATABLE_ZONE_CONTAINER,
} from "./constants";

const BlogBodyV4 = ({ data, items }) => {
  return (
    <div>
      <MontserratXLNormal text={data?.description} />
      {items?.map((item, index) => {
        return (
          <div className={REPEATABLE_ZONE_CONTAINER} key={index}>
            <MontserratXLNormal text={item?.description} />
            {item?.image_1?.dimensions && (
              <div className={BLOG_IMAGES_CONTAINER}>
                {item?.image_1?.dimensions && (
                  <div className={BLOG_BODY_IMAGE_CONTAINER}>
                    <BlogBodyImage
                      image={item?.image_1}
                      imageDescription={item?.image_1_description}
                      pinterestLink={item?.pinterest_link_1}
                    />
                  </div>
                )}
                {item?.image_2?.dimensions && (
                  <div className={BLOG_BODY_IMAGE_CONTAINER}>
                    <BlogBodyImage
                      image={item?.image_2}
                      imageDescription={item?.image_2_description}
                      pinterestLink={item?.pinterest_link_2}
                    />
                  </div>
                )}
                {item?.image_3?.dimensions && (
                  <div className={BLOG_BODY_IMAGE_CONTAINER}>
                    <BlogBodyImage
                      image={item?.image_3}
                      imageDescription={item?.image_3_description}
                      pinterestLink={item?.pinterest_link_3}
                    />
                  </div>
                )}
              </div>
            )}
            <div>
              {item?.button_text && (
                <PrimaryButton
                  text={item?.button_text}
                  href={item?.button_link}
                  buttonId={item?.button_id}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlogBodyV4;
