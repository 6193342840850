import React from "react";
import {
  FaqSlice,
  DashboardFaqSlice,
} from "../../components/Molecules/FaqSlice";
import { DASHBOARD_FAQ_SLICE_TYPE } from "../../public/Constants/EnumConstants";

/**
 * @typedef {import("@prismicio/client").Content.FaqSlice} FaqSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<FaqSlice>} FaqProps
 * @param { FaqProps }
 */
const Faq = ({ slice }) => {
  const FaqVariant =
    slice?.variation === DASHBOARD_FAQ_SLICE_TYPE ? (
      <DashboardFaqSlice slice={slice} />
    ) : (
      <FaqSlice slice={slice} />
    );
  return FaqVariant;
};

export default Faq;
