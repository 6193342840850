import React from "react";
import ProductVariantsSection from "../../components/Molecules/ProductVariantsSection";

/**
 * @typedef {import("@prismicio/client").Content.ProductVariantsSlice} ProductVariantsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<ProductVariantsSlice>} ProductVariantsProps
 * @param { ProductVariantsProps }
 */

const ProductVariants = ({ slice, variant, setVariant }) => {
  return (
    <>
      {slice?.items && (
        <section>
          <ProductVariantsSection
            data={slice?.primary}
            items={slice?.items}
            variant={variant}
            setVariant={setVariant}
          />
        </section>
      )}
    </>
  );
};

export default ProductVariants;
