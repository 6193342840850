export const SETTINGS = (videoIndex) => ({
  dots: true,
  slidesToShow: 1,
  autoplay: false,
  speed: 500,
  cssEase: "linear",
  draggable: true,
  swipe: true,
  infinite: true,
  arrows: false,
  customPaging: (index) => {
    const isVideo = videoIndex === index;
    const dotClassName = isVideo ? "slick-video-dot" : "";
    return <button className={dotClassName}></button>;
  },
});

export const WRAPPER_CONTAINER =
  " grid place-content-center my-10 w-full relative ";
export const INNER_CONTAINER = " serena-custom-dot max-w-[430px]  ";
export const MEDIA_CLASS =
  " max-w-full sm:max-w-[430px] aspect-[6/10] object-cover";
export const VIDEO_CONTAINER = " max-w-[430px] relative  mx-auto ";
