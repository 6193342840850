export const RECIPIENT_DETAILS_FORM =
  "bg-Form_Background rounded-lg border border-Tile_Outlines mx-auto w-[100%] pb-[5%]";
export const RECIPIENT_DETAILS_FORM_SUB_CONTAINER = "w-[90%] mx-auto";
export const FIELDS_CONTAINER = " text-[16px]";
export const FIELDS_ROW_CONTAINER =
  "grid grid-cols-1 md:grid-cols-2 md:gap-x-[33px] mt-[5%] md:mb-[5%]";
export const GIFT_CARD_HEADING =
  "text-center text-Montserrat text-xl md:text-2xl font-medium leading-[30px] md:leading-9 mt-[20px] md:mt-[30px]";
export const TEXT_AREA_CONTAINER = "grid";
export const TEXT_TYPE = "text";
export const EMAIL_TYPE = "email";
export const NUMBER_TYPE = "number";

export const RECEIVER_FIRST_NAME_FIELD = "recipient_first_name";
export const RECEIVER_LAST_NAME_FIELD = "recipient_last_name";
export const RECEIVER_EMAIL_FIELD = "recipient_email";
export const GIFT_CARD_AMOUNT_FIELD = "gift_card_amount";
export const MESSAGE_FIELD = "gift_message";
