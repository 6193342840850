import React from "react";

import NewStylistSection from "../../components/Organisms/NewStylistSection";
import StylistSection from "../../components/Organisms/StylistSection";

const Stylists = ({ slice }) => {
  let Variation = <StylistSection data={slice} />;

  if (slice?.variation === "newStylist") {
    Variation = <NewStylistSection data={slice} />;
  }
  return (
    <section>
      {Variation}
      <style jsx>{`
        section {
          // margin: 6em auto;
          text-align: right;
        }
        @media screen and (max-width: 500px) {
          section {
            // margin: 3em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default Stylists;
