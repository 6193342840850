import React from "react";
import dynamic from "next/dynamic";

import {
  HOW_IT_WORKS,
  HOW_IT_WORKS_FIRST_ROW,
  HOW_IT_WORKS_SECOND_ROW,
  HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT,
  HOW_WE_WORK_MAIN_CONTAINER,
  HOW_WE_WORK_STYLE_CLASS,
  HOW_WE_WORK_TITLE,
  HOW_WE_WORK_WRAPPER_CONTAINER,
} from "./constants";
import {
  ENGLISH,
  SIGN_UP_ENDPOINT,
  SURVEY_START_SECTIONS,
  ZERO,
} from "../../../public/Constants/EnumConstants";
import { PrismicRichText } from "@prismicio/react";
import NewPrimaryButtonWrapper from "../../Atoms/Buttons/NewPrimaryButtonWrapper";

const HowWeWorksCard = dynamic(() => import("../../Molecules/HowWeWorksCard"));


const HowWeWorkSection = ({ slice }) => {
  return (
    <div id={HOW_IT_WORKS} className={HOW_WE_WORK_WRAPPER_CONTAINER}>
      <div className={HOW_WE_WORK_MAIN_CONTAINER}>
        <div className={HOW_WE_WORK_STYLE_CLASS}>
          <PrismicRichText
            field={slice?.primary?.title}
            components={{
              heading1: ({ children }) => (
                <p className={HOW_WE_WORK_TITLE}>{children}</p>
              ),
            }}
          ></PrismicRichText>
        </div>
        <div className={HOW_IT_WORKS_FIRST_ROW}>
          {slice?.items?.map((item, index) => (
            <HowWeWorksCard
              key={index}
              index={index}
              step={item?.index}
              heading={item?.title}
              description={item?.description}
              image={item?.image}
            />
          ))}
        </div>
        <div className={HOW_IT_WORKS_SECOND_ROW}>
          <NewPrimaryButtonWrapper
            text={slice?.primary?.button_text}
            href={SIGN_UP_ENDPOINT}
            section={SURVEY_START_SECTIONS?.HowWeWorks}
            buttonId={HOW_IT_WORKS_SECTION_BUTTON_ID_TEXT}
          />
        </div>
      </div>
    </div>
  );
};

export default HowWeWorkSection;
