import React from "react";
import WhoWeAreSection from "../../components/Molecules/WhoWeAreSection";
import WhoWeAreSectionVersion2 from "../../components/Molecules/WhoWeAreSectionVersion2";
import { VERSION_2 } from "../../public/Constants/EnumConstants";

const WhoWeAre1 = ({ slice }) => {
  return (
    <section>
      {slice?.variation === VERSION_2 ? (
        <WhoWeAreSectionVersion2 data={slice?.primary} />
      ) : (
        <WhoWeAreSection data={slice?.primary} />
      )}
      <style jsx>{`
        section {
          max-width: 1920px;
          margin: 6em auto;
          text-align: right;
        }
        @media screen and (max-width: 500px) {
          section {
            margin: 3em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default WhoWeAre1;
