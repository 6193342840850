import React from "react";
import StylistProfileAdviceSection from "../../components/Organisms/StylistProfileAdviceSection";
import StylistProfileVideo from "../../components/Organisms/StylistProfileVideo";

const StylistProfileAdvice = ({ slice }) => {
  let renderSection = <StylistProfileAdviceSection slice={slice} />;
  if (slice?.variation === "stylistProfileVideo") {
    renderSection = <StylistProfileVideo slice={slice} />;
  }

  return (
    <section>
      {renderSection}
      <style jsx>{`
        section {
          margin: 7.5em auto;
          text-align: center;
          max-width: 1440px;
          display: flex;
          justify-content: center;
        }
        @media screen and (max-width: 500px) {
          section {
            margin: 5em auto;
          }
        }
      `}</style>
    </section>
  );
};

export default StylistProfileAdvice;
