import React from "react";
import { ClockIcon } from "../../../../public/Theme";
import dynamic from "next/dynamic";
import { INPUT_CONTAINER, LABEL, INPUT_FIELD_ERROR } from "./constants";
import { INPUT_FIELD } from "./constants";
import { InputErrorMessage } from "../../Typography/InputErrorMessage";
import { useRouter } from "next/navigation";
const DynamicTimePicker = dynamic(
  () => import("react-time-picker/dist/entry.nostyle"),
  {
    ssr: false,
  }
);

const TimePickerField = ({ value, label, field, errors, onChange, onBlur }) => {
  const inputClassName = errors[field] ? INPUT_FIELD_ERROR : INPUT_FIELD;
  const { locale } = useRouter();
  return (
    <div className={INPUT_CONTAINER}>
      <p className={LABEL}>{label}</p>
      <DynamicTimePicker
        className={inputClassName}
        closeClock
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        dropdownMode="scroll"
        disableClock
        amPmAriaLabel="Select AM/PM"
        hourPlaceholder="hh"
        minutePlaceholder="mm"
        clockIcon={<img src={ClockIcon} alt={"Clock Icon"} />}
        clearIcon={null}
        locale={locale}
      />

      <InputErrorMessage errorMessage={errors[field]?.message} />
    </div>
  );
};

export default TimePickerField;
