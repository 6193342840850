import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { SECTION_HEADING_CLASS } from "./constants";

const SectionHeading = ({ text, simpleText }) => {
  return simpleText ? (
    <h1 className={SECTION_HEADING_CLASS}>{simpleText}</h1>
  ) : (
    <PrismicRichText
      field={text}
      components={{
        heading1: ({ children }) => (
          <h1 className={SECTION_HEADING_CLASS}>{children}</h1>
        ),
        paragraph: ({ children }) => (
          <h1 className={SECTION_HEADING_CLASS}>{children}</h1>
        ),
      }}
    ></PrismicRichText>
  );
};

export default SectionHeading;
