import { ARABIC } from "../../../../public/Constants/EnumConstants";

export const INPUT_CONTAINER = "flex flex-col mx-auto";
export const CHECK_BOX_CONTAINER = "flex flex-row items-justify";
export const CHECK_BOX_INPUT = " peer opacity-0 absolute h-6 w-6";
export const CUSTOM_BOX_CONTAINER =
  "border-2 peer-checked:bg-New_Primary_Default rounded border-New_Primary_Default w-6 h-6 flex flex-shrink-0 justify-center items-center focus-within:border-New_Primary_Default";

export const LABEL = (locale) =>
  locale === ARABIC
    ? "mr-[10px] md:mr-[15px]"
    : "ml-[10px] md:ml-[15px]" +
      " font-normal md:font-medium text-sm md:text-base text-Heading_Black text-Montserrat";
