import Image from "next/image";
import React from "react";
import { NewRightArrow } from "../../../../public/Theme";

const NewCenterAlignedRoundRightArrow = ({ className, style, onClick }) => {
  return (
    <button className={className} style={{ ...style }} onClick={onClick}>
      <Image alt="right arrow" src={NewRightArrow} fill sizes="100vw" />
    </button>
  );
};

export default NewCenterAlignedRoundRightArrow;
