// Code generated by Slice Machine. DO NOT EDIT.

import AboutUsBanner from './AboutUsBanner';
import AboutUsTextSection from './AboutUsTextSection';
import AmbassadorPerksAndBenefits from './AmbassadorPerksAndBenefits';
import AmbassadorProgramForm from './AmbassadorProgramForm';
import ApplicationSubmitted from './ApplicationSubmitted';
import Banner from './Banner';
import BecomeAnAmbassadorBanner from './BecomeAnAmbassadorBanner';
import BlogSection from './BlogSection';
import Catalog from './Catalog';
import ECommerceBanner from './ECommerceBanner';
import Faq from './Faq';
import FullyStyleInclusiveSection from './FullyStyleInclusiveSection';
import GiftCardForm from './GiftCardForm';
import HowItWorks from './HowItWorks';
import HowToWearThat from './HowToWearThat';
import HowWeWork from './HowWeWork';
import ImageCarousel from './ImageCarousel';
import JournalsSection from './JournalsSection';
import LandingPopup from './LandingPopup';
import LatestDrops from './LatestDrops';
import News from './News';
import NextSteps from './NextSteps';
import OurValues from './OurValues';
import ProductCard from './ProductCard';
import ProductDetails from './ProductDetails';
import ProductFlatlays from './ProductFlatlays';
import ProductVariants from './ProductVariants';
import StyleAdvice from './StyleAdvice';
import StylingSession from './StylingSession';
import StylingSessionBanner from './StylingSessionBanner';
import StylingSessionForm from './StylingSessionForm';
import StylistDataSection from './StylistDataSection';
import StylistProfileAdvice from './StylistProfileAdvice';
import Stylists from './Stylists';
import Testimonials from './Testimonials';
import ThisOrThat from './ThisOrThat';
import Timeline from './Timeline';
import WhoWeAre1 from './WhoWeAre1';

export {
	AboutUsBanner,
	AboutUsTextSection,
	AmbassadorPerksAndBenefits,
	AmbassadorProgramForm,
	ApplicationSubmitted,
	Banner,
	BecomeAnAmbassadorBanner,
	BlogSection,
	Catalog,
	ECommerceBanner,
	Faq,
	FullyStyleInclusiveSection,
	GiftCardForm,
	HowItWorks,
	HowToWearThat,
	HowWeWork,
	ImageCarousel,
	JournalsSection,
	LandingPopup,
	LatestDrops,
	News,
	NextSteps,
	OurValues,
	ProductCard,
	ProductDetails,
	ProductFlatlays,
	ProductVariants,
	StyleAdvice,
	StylingSession,
	StylingSessionBanner,
	StylingSessionForm,
	StylistDataSection,
	StylistProfileAdvice,
	Stylists,
	Testimonials,
	ThisOrThat,
	Timeline,
	WhoWeAre1,
};

export const components = {
	about_us_banner: AboutUsBanner,
	about_us_text_section: AboutUsTextSection,
	ambassador_perks_and_benefits: AmbassadorPerksAndBenefits,
	ambassador_program_form: AmbassadorProgramForm,
	application_submitted: ApplicationSubmitted,
	banner: Banner,
	become_an_ambassador_banner: BecomeAnAmbassadorBanner,
	blog_section: BlogSection,
	catalog: Catalog,
	e_commerce_banner: ECommerceBanner,
	faq: Faq,
	fully_style_inclusive_section: FullyStyleInclusiveSection,
	gift_card_form: GiftCardForm,
	how_it_works: HowItWorks,
	how_to_wear_that: HowToWearThat,
	how_we_work: HowWeWork,
	image_carousel: ImageCarousel,
	journals_section: JournalsSection,
	landing_popup: LandingPopup,
	latest_drops: LatestDrops,
	news: News,
	next_steps: NextSteps,
	our_values: OurValues,
	product_card: ProductCard,
	product_details: ProductDetails,
	product_flatlays: ProductFlatlays,
	product_variants: ProductVariants,
	style_advice: StyleAdvice,
	styling_session: StylingSession,
	banner_styling_session: StylingSessionBanner,
	styling_session_form: StylingSessionForm,
	stylist_data_section: StylistDataSection,
	stylist_profile_advice: StylistProfileAdvice,
	stylists: Stylists,
	testimonials: Testimonials,
	this_or_that: ThisOrThat,
	timeline: Timeline,
	who_we_are1: WhoWeAre1,
};
