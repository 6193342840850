import { ENGLISH } from "../../../public/Constants/EnumConstants";
export const POSITION_RELATIVE =
  " flex justify-center h-max  relative overflow-hidden ";
export const HERO_MOBILE_VIDEO_CLASS = "sm:hidden w-[100%] h-auto";
export const BANNER_IMAGE_CLASS = "w-full h-auto object-cover";
export const HERO_CONTENT_CONTAINER =
  "absolute flex justify-center	items-center max-w-[800px] h-full ";
export const HERO_CONTENT_INNER_CONTAINER =
  " my-16 mx-3  !text-center xl:!max-w-[95%]";
export const HERO_CONTENT_INNER_CONTAINER_AR =
  " my-16 mx-3 sm:ml-[13%] !text-center md:!text-center xl:!max-w-[95%]";
export const HERO_BANNER_CTA_CLASS =
  "!bg-black hover:!bg-LIGHT_BLACK !text-white";
export const BANNER_BUTTON_ID_TEXT = "hero-banner-section-button";
export const CONTROLS_CONTAINER =
  "absolute top-0 right-0 left-0 bottom-0 flex flex-col my-auto justify-between cursor-pointer";
export const PLAY_BUTTON_CONTAINER =
  "flex-col items-center justify-center h-max w-max m-auto";
export const VIDEO_OVERLAY = "opacity-80";
export const PLAY_BUTTON_ICON = "h-full w-full";
export const TEXT_6XL =
  "!text-4xl lg:!text-6xl !font-extrabold !my-0 lg:!my-3 text-white	";
export const WEAR_THAT_LOGO_CLASS =
  "mb-8 sm:mb-2 xl:mb-6 px-[25%] w-full h-full ";
export const BUTTON_CLASS = "mt-6 mb-5";
export const TAG_LINE_CLASS = "mx-4 not-italic";
export const WEAR_THAT_TEXT = "wear that";
