export const OVER_LAY_HEADING =
  "my-[135px] sm:my-[75px] md:my-[75px] lg:my-[145px] bg-[#FFFFFF80] h-max w-full justify-center self-center text-center font-Playfair";

export const BACKGROUND_IMAGE_CONTAINER_CLASS =
  " w-[80%] flex justify-center items-center bg-no-repeat bg-contain bg-center bg-Tile_Background mx-auto mt-[4%] md:mt-[2%]";

export const BREAK_POINT_WIDTH = 425;

export const SECTION_HEADING = "mt-[30px]";
export const BANNER_TOP_LINE = "mt-[30px] md: mt-[35px] text-xl";
export const BANNER_HIGHLIGHT_LINE = "mt-[16px] md:mt-[12px] w-[70%] mx-auto";
export const BANNER_HIGHLIGHT_LINE_STYLE = "md:leading-[50px]";
export const BANNER_DESCRIPTION =
  "mt-[16px] md:mt-[20px] w-[90%] md:w-[65%] mx-auto";
export const BANNER_BOTTOM_LINE = "mt-[8px] md:mt-[4px]";
export const BANNER_BUTTON = "my-[30px] md:my-[35px]";
