import { ENGLISH } from "../../../public/Constants/EnumConstants";

export const CONTAINER_CLASS =
  " mx-5 md:mx-10  grid grid-col-1 lg:grid-cols-3 place-content-start";
export const TEXT_CONTAINER_CLASS_ARABIC = " text-right lg:mx-10 lg:col-span-2";
export const TEXT_CONTAINER_CLASS = " text-left lg:mx-10 lg:col-span-2";
export const INNER_CONTAINER_CLASS =
  "flex justify-between items-center w-[inherit]";
const QOUTES_ICON_CLASS = "h-4 sm:h-auto";
const ARABIC_QOUTES_ICON_CLASS = "h-4 sm:h-auto ml-auto";
export const IMAGE_STYLE = "rounded-lg";
export const qoutesAligmentClass = (language) => {
  return language === ENGLISH ? QOUTES_ICON_CLASS : ARABIC_QOUTES_ICON_CLASS;
};
export const ABOUT_ME = "Little About Me";
export const PERSONAL_STYLE = "My Personal Style";
export const INSIDER_INFO = "Insider Info";
export const IMAGE_CLASS = "border-4 border-Tile_Outlines mb-2";
