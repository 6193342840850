"use client";
import React from "react";

import GiftCardFormSection from "../../components/Molecules/GiftCardFormSection";

const GiftCardForm = ({ slice }) => (
  <section>
    <GiftCardFormSection data={slice?.primary} items={slice?.items} />
    <style jsx>{`
      section {
        margin: auto;
      }
      @media screen and (max-width: 500px) {
        section {
          margin: auto;
        }
      }
    `}</style>
  </section>
);

export default GiftCardForm;
