import React from "react";
import {
  BACKGROUND_IMAGE_CONTAINER_CLASS,
  OVER_LAY_HEADING,
  BREAK_POINT_WIDTH,
  SECTION_HEADING,
  BANNER_TOP_LINE,
  BANNER_HIGHLIGHT_LINE,
  BANNER_DESCRIPTION,
  BANNER_BOTTOM_LINE,
  BANNER_BUTTON,
  BANNER_HIGHLIGHT_LINE_STYLE,
} from "./constants";
import SectionHeading from "../../Atoms/Typography/SectionHeading";
import ParagraphRegular from "../../Atoms/Typography/ParagraphRegular";
import PrimaryButton from "../../Atoms/Buttons/PrimaryButton/PrimaryButton";
import MontserratXLNormal from "../../Atoms/Typography/MontserratXLNormal";
import Montserrat2xlMedium from "../../Atoms/Typography/Montserrat2xlMedium";
import Playfair4xlSemibold from "../../Atoms/Typography/Playfair4xlSemibold";
import { MY_REWARDS_LINK } from "../../../public/Constants/EnumConstants";

const ReferAFriendBanner = ({ data }) => {
  return (
    <div>
      <div className={SECTION_HEADING}>
        <SectionHeading text={data?.title} />
      </div>
      <div className={BACKGROUND_IMAGE_CONTAINER_CLASS}>
        <div>
          {/* <InStudioStylingHeading text={data?.title?.[0]?.text} /> */}
          <div className={BANNER_TOP_LINE}>
            <MontserratXLNormal text={data?.banner_top_line} />
          </div>
          <div className={BANNER_HIGHLIGHT_LINE}>
            <Playfair4xlSemibold
              text={data?.highlight_line}
              customStyle={BANNER_HIGHLIGHT_LINE_STYLE}
            />
          </div>
          <div className={BANNER_DESCRIPTION}>
            <ParagraphRegular text={data?.description} />
          </div>
          <div className={BANNER_BOTTOM_LINE}>
            <Montserrat2xlMedium text={data?.amount_limit_sentence} />
          </div>
          <div className={BANNER_BUTTON}>
            <PrimaryButton text={data?.button_text} href={MY_REWARDS_LINK} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferAFriendBanner;
